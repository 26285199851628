<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h4 class="col-md-12">Section VI. Complementary information</h4>
                <div v-if="procurement_stage == FATNoticeTypes.F02.value">
                    <h5 class="col-md-12">VI.2. Information about electronic workflows</h5>
                    <div class="col-md-6">
                        <div :class="{ 'has-error-field': v.form.recurrent_procurement.$invalid }">
                            <el-radio-group v-model="form.recurrent_procurement">
                                <el-radio :label="1" :value="1">Yes</el-radio>
                                <el-radio :label="0" :value="0">No</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.recurrent_procurement.$invalid" class="error-text" >Town/City is required</div>
                    </div>
                    <div class="col-md-6" v-if="form.recurrent_procurement">
                        <br/>Estimated timing for further notices to be published (optional)
                        <el-input label="Official Name" v-model="form.further_notices_timing"></el-input>
                    </div>
                    <div class="col-md-6">
                        <label>VI.2. Information about electronic workflows</label>
                        <el-form-item label="" prop="name">
                            <el-checkbox v-model="form.electronic_ordering" label="Electronic ordering will be used (optional)" size="large"
                            :checked="electronic_ordering" />
                            <el-checkbox v-model="form.electronic_invoicing" label="Electronic invoicing will be accepted (optional)" size="large"
                            :checked="electronic_invoicing" />
                            <el-checkbox v-model="form.electronic_payment" label="Electronic payments will be used (optional)" size="large"
                            :checked="electronic_payments" />
                        </el-form-item>
                    </div>
                </div>
                <div class="col-md-12">
                    <label>VI.3. Additional information (optional)</label>
                        <el-input v-model="form.additional_information" type="textarea" :rows="3"></el-input>
                    <label><br/></label>
                </div>
                <hr/>
                <div v-if="procurement_stage == FATNoticeTypes.F02.value">
                    <h5 class="col-md-12">VI.4. Procedures for review</h5>
                    <label class="col-md-12">VI.4.1. Review body</label>
                    <div class="col-md-3">
                        <br/><label>Official Name</label>
                        <div :class="{ 'has-error-field': v.form.official_name.$invalid }">
                            <el-input label="Official Name" v-model="form.official_name"></el-input>
                        </div>
                        <div v-if="v.form.official_name.$invalid" class="error-text" >Official name is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Address (optional)</label>
                        <el-input label="Address (optional)" v-model="form.address"></el-input>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Town/City</label>
                        <div :class="{ 'has-error-field': v.form.official_name.$invalid }">
                            <el-input label="Town/City" v-model="form.town_city"></el-input>
                        </div>
                        <div v-if="v.form.town_city.$invalid" class="error-text" >Town/City is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Postalcode (optional)</label>
                        <el-input label="Postalcode (optional)" v-model="form.postal_code"></el-input>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Country</label>
                        <div :class="{ 'has-error-field': v.form.country.$invalid }">
                            <el-input label="Country" v-model="form.country"></el-input>
                        </div>
                        <div v-if="v.form.country.$invalid" class="error-text" >Country is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Email Address (optional)</label>
                        <el-input label="Email Address (optional)" v-model="form.email_address"></el-input>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Telephone (optional)</label>
                        <el-input label="Telephone (optional)" v-model="form.telephone"></el-input>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Fax (optional)</label>
                        <el-input label="Fax (optional)" v-model="form.fax"></el-input>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Internet Address (optional)</label>
                        <el-input label="Internet Address (optional)" v-model="form.internet_address"></el-input>
                    </div>
                    <div class="col-md-12">
                        <br/><label>VI.4.2. Body responsible for mediation procedures</label>
                        <br/><el-checkbox label="There is a body responsible for mediation procedures (optional)" v-model="form.mediation_procedures" :checked="form.mediation_procedures"></el-checkbox>
                    </div>
                    <div v-if="form.mediation_procedures" class="row">
                        <div class="col-md-3">
                            <br/><label>Official Name</label>
                            <div :class="{ 'has-error-field': v.form.mediation_procedures_official_name.$invalid }">
                                <el-input label="Official Name" v-model="form.mediation_procedures_official_name"></el-input>
                            </div>
                            <div v-if="v.form.mediation_procedures_official_name.$invalid" class="error-text">Official Name is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Address (optional)</label>
                            <el-input label="Address (optional)" v-model="form.mediation_procedures_address"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Town/City</label>
                            <div :class="{ 'has-error-field': v.form.mediation_procedures_town_city.$invalid }">
                                <el-input label="Town/City" v-model="form.mediation_procedures_town_city"></el-input>
                            </div>
                            <div v-if="v.form.mediation_procedures_town_city.$invalid" class="error-text" >Town/City is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Postalcode (optional)</label>
                            <el-input label="Postalcode (optional)" v-model="form.mediation_procedures_postal_code"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Country</label>
                            <div :class="{ 'has-error-field': v.form.mediation_procedures_country.$invalid }">
                                <el-input label="Country" v-model="form.mediation_procedures_country"></el-input>
                            </div>
                            <div v-if="v.form.mediation_procedures_country.$invalid" class="error-text" >Country is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Email Address (optional)</label>
                            <el-input label="Email Address (optional)" v-model="form.mediation_procedures_email_address"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Telephone (optional)</label>
                            <el-input label="Telephone (optional)" v-model="form.mediation_procedures_telephone"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Fax (optional)</label>
                            <el-input label="Fax (optional)" v-model="form.mediation_procedures_fax"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Internet Address (optional)</label>
                            <el-input label="Internet Address (optional)" v-model="form.mediation_procedures_internet_address"></el-input>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label>VI.4.3. Review procedure</label>
                        <el-input v-model="form.review_procedures" label="Precise information on deadline(s) for review procedures (optional)"></el-input>
                    </div>
                    <div v-if="form.service_review_procedure" class="row">
                        <div class="col-md-3">
                            <br/><label>Official Name</label>
                            <div :class="{ 'has-error-field': v.form.service_review_procedure_official_name.$invalid }">
                                <el-input label="Official Name" v-model="form.service_review_procedure_official_name"></el-input>
                            </div>
                            <div v-if="v.form.service_review_procedure_official_name.$invalid" class="error-text" >Official Name is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Address (optional)</label>
                            <el-input label="Address (optional)" v-model="form.service_review_procedure_address"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Town/City</label>
                            <div :class="{ 'has-error-field': v.form.service_review_procedure_town_city.$invalid }">
                                <el-input label="Town/City" v-model="form.service_review_procedure_town_city"></el-input>
                            </div>
                            <div v-if="v.form.service_review_procedure_town_city.$invalid" class="error-text" >Town/City is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Postalcode (optional)</label>
                            <el-input label="Postalcode (optional)" v-model="form.service_review_procedure_postal_code"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Country</label>
                            <div :class="{ 'has-error-field': v.form.service_review_procedure_country.$invalid }">
                                <el-input label="Country" v-model="form.service_review_procedure_country"></el-input>
                            </div>
                            <div v-if="v.form.service_review_procedure_country.$invalid" class="error-text" >Country is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Email Address (optional)</label>
                            <el-input label="Email Address (optional)" v-model="form.service_review_procedure_email_address"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Telephone (optional)</label>
                            <el-input label="Telephone (optional)" v-model="form.service_review_procedure_telephone"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Fax (optional)</label>
                            <el-input label="Fax (optional)" v-model="form.service_review_procedure_fax"></el-input>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Internet Address (optional)</label>
                            <el-input label="Internet Address (optional)" v-model="form.service_review_procedure_internet_address"></el-input>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <br/><label>VI.4.4. Service from which information about the review procedure may be obtained</label>
                        <br/><el-checkbox label="There is a service from which information about the review procedure may be obtained (optional)" v-model="form.service_review_procedure" :checked="form.service_review_procedure"></el-checkbox>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form ? v.form.$invalid : false" @click="saveForm()">Save and go to Next Section</el-button></span>
</template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'

     const SECTION_SIX = 6
 
     export default {
         props: ['user', 'procurement_id', 'saved_form', 'procurement_stage', "is_not_submitted"],
         data() {
             return {
                form: {
                    contract_number: null,
                    lot_number: null,
                    title: null,
                    lot_is_awarded: null,
                    // lot_is_awarded == yes
                    contract_conclusion_date: null,
                    tenders_received: null,
                    tenders_received_sme: null,
                    tenders_received_electronic: null,
                    awarded_group_operators: null,
                    // awarded_group_operators == yes
                    total_economic_operator: null,
                    // V.2.3
                    official_name: null,
                    identification_register: null,
                    address: null,
                    town_city: null,
                    postal_code: null,
                    country: null,
                    nuts_code: null,
                    email_address: null,
                    telephone: null,
                    fax: null,
                    main_address: null,
                    is_sme: null,
                    //V.2.4
                    initial_lot_value: null,
                    currency: null,
                    total_lot: null,
                    // total_lot == 0
                    exact_value: null,
                    // end-total-lot
                    // total_lot == 1
                    lowest_offer: null,
                    highest_offer: null,
                    // end-total-lot 
                    total_lot_currency: null,
                    // V.2.5
                    subcontracted: null,
                    // end_awarded_group_operators == yes

                }         
             }
         },
        setup: () => ({
             v: useVuelidate()
          }),
        validations() {
            let rules = {
                form: {
                    lot_is_awarded: {
                        required,
                        $autoDirty: true
                    },
                    mediation_procedures_official_name: {
                        required: requiredIf(function () {return this.form.mediation_procedures}),
                        $autoDirty: true
                    },
                    mediation_procedures_town_city: {
                        required: requiredIf(function () {return this.form.mediation_procedures}),
                        $autoDirty: true
                    },
                    mediation_procedures_country: {
                        required: requiredIf(function () {return this.form.mediation_procedures}),
                        $autoDirty: true
                    },
                    service_review_procedure_official_name: {
                        required: requiredIf(function () {return this.form.service_review_procedure}),
                        $autoDirty: true
                    },
                    service_review_procedure_town_city: {
                        required: requiredIf(function () {return this.form.service_review_procedure}),
                        $autoDirty: true
                    },
                    service_review_procedure_country: {
                        required: requiredIf(function () {return this.form.service_review_procedure}),
                        $autoDirty: true
                    },
                }   
            }

            return rules;
        },
        mounted()  {
            if(this.saved_form) this.form = this.saved_form
        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts']),
        },
        methods: {
            saveForm() {
                if(!this.form.mediation_procedures) {
                    this.form.mediation_procedures_official_name = null
                    this.form.mediation_procedures_town_city = null
                    this.form.mediation_procedures_country = null
                }

                if(!this.form.service_review_procedure) {
                    this.form.service_review_procedure_official_name = null
                    this.form.service_review_procedure_town_city = null
                    this.form.service_review_procedure_country = null
                }

                this.$emit('save', SECTION_SIX, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form;
            },
        }
     }
 </script>
 