<template>
<div>
  <el-drawer :title="user ? 'Upload Files for ' + user.name : 'Upload Files'" v-model="showAdd" direction="rtl">
    <supplier-hub-file-form v-if="showAdd" @update="filesUpdated()" 
    :personal="true" :user="user" :pin_notice_id="pin_notice_id"
     :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice"
     :lot_id="lot_id"
     ></supplier-hub-file-form>
  </el-drawer>

  <div style="text-align: left;" class="row" v-if="itt_framework">
    <div class="col-md-12">
      <div class="card my-3" style="min-height: 500px;">
        <div class="card-body px-0 pb-2">
          <div class="px-4">
            <div class="row">
              <div class="col-md-8">
                <div class="px-4">
                  <h4>
                    {{
                    user
                      ? 'Owner: ' + user.name + ' - ' + user.company_name
                      : ( if_in_manage == 1 ? itt_framework.name : 'My File Library' )
                  }}
                  </h4>
                  <div v-if="!user" v-html="itt_framework.my_files_description"></div>
                  <!-- <span class="text-wrap">
                  {{ user ? '' : 'Please upload your completed Supplier Input spreadsheet and SQ Questions form here. Please also use this function to upload your terms & conditions and any other files which you would like to include with your response' }}
                </span> -->
                </div>
              </div>
              <div class="col-md-4">
                <el-button v-tooltip="'Close'" type="danger" size="large" @click="close()" v-if="if_in_manage == 1" class="float-end me-3">
                  <i class="fa fa-times"></i>&nbsp;
                  Close</el-button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <el-form :inline="true" class="demo-form-inline" @submit.prevent="getFiles($event)">
                    <el-form-item>
                      <el-button v-tooltip="'Download'" type="" size="small" @click="downloadAll()" 
                      v-if="countSelectedDownloads()
                        &&
                        ((itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived) )
                      ">
                        <i class="fa-solid fa-download text-secondary"></i>
                        Download selected file{{
                        countSelectedDownloads() > 1 ? 's' : ''
                        }}
                        {{ countSelectedDownloads() }}</el-button>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="create()" size="small" v-if="
                              (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived)
                              
                          ">Add</el-button>
                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>
            <div class="table-responsive" v-loading="loading">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th style="width: 10px;">
                      <el-checkbox :disabled="!files.length" v-tooltip="'Select All'" :checked="select_all" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" @change="handleSelectAll($event)"></el-checkbox>
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width: 50%;">
                      File Name
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width: 30%;">
                      Uploaded By</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width: 10px;">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!files.length && !loading">
                    <td colspan="4" class="disabled">No data found.</td>
                  </tr>
                  <tr v-for="file in files" :key="file.id">
                    <td class="text-sm">
                      <el-checkbox :checked="isSelected(file)" @change="selectToDownload($event, file)" v-model="file.selected"></el-checkbox>
                    </td>
                    <td class="text-sm">{{ file.file_name }}</td>
                    <td class="text-sm">{{ file?.user?.name }}</td>
                    <td class="text-sm" style="width: 1%">
                      <a class="link me-3 " 
                      @click="downloadSingleFile(file.file.storage_path)"
                      v-if="file.file &&
                      (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                              (!itt_framework.is_deadline_passed && !itt_framework.is_archived)
                      " v-tooltip="'Download'">
                        <i class="fa fa-solid fa-download text-secondary"></i>
                      </a>
                      <a class="link me-3 " v-allowed="'my_files, delete'" v-if="(itt_framework && !itt_framework.is_archived) ||
      !itt_framework
      " @click="remove(file)" v-tooltip="'Delete'">
                        <i class="fa-solid fa-trash text-danger"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination :meta="meta" v-on:update="getFiles()" style="padding: 10px" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  SupplierHubFile
} from '@/api_services/supplierhub_file'
import SupplierHubFileForm from './SupplierHubFileForm.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    SupplierHubFileForm
  },
  props: {
    lot_id: {
      type: Number,
      required: false,
      default: null
    },
    user: {
      type: Object,
      required: false,
      default: null
    },
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    },
    if_in_manage: {
      type: Number,
      required: false,
      default: 0
    }
    
  },
  data() {
    return {
      company_ids: [],
      itt_framework_id: null,
      pin_notice_id: null,
      showAdd: false,
      files: [],
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      download_files: [],
      select_all: false,
      loading: false
    }
  },
  watch: {
    user: function () {
      this.getFiles()
    },
    itt_framework: function () {
      this.meta.page = 1
      this.getFiles()
    },
  },
  computed: {
    this_user() {
      return this.$store.state.savedUser 
    }
  },
  created: function () {
    if (this.if_pin_notice === 1) {
      this.pin_notice_id = this.itt_framework?.id
    } else {
      this.itt_framework_id = this.itt_framework?.id
    }
    this.getFiles()

    for (const [key, value] of Object.entries(this.this_user.companies)) {
      this.company_ids.push(value.id)
    }
  },
  methods: {
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    downloadSingleFile(file){
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to download this file!",
          icon: 'warning',
        })
        return false
      }

      window.location.assign(file)
    },
    close() {
      this.$emit("close_file", false)
    },
    downloadAll() {

      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to download these files!",
          icon: 'warning',
        })
        return false
      }

      let ids = []
      this.$loading = true
      this.download_files.forEach(function (file) {
        if (file.selected) ids.push(file.id)
      })
      SupplierHubFile.downloadFiles({
          params: {
          ids: ids,
          }
        })
        .then(result => {
          const link = document.createElement('a')
          link.href = result.data.file

          // Set the file name that will be downloaded
          link.setAttribute('download', 'file-name-to-download.zip') // Replace with the desired file name

          // Hide the link
          link.style.display = 'none'

          // Append the link to the document body
          document.body.appendChild(link)

          // Simulate a click on the link to trigger the download
          link.click()

          // Remove the link from the DOM
          document.body.removeChild(link)
        })
        .catch(error => {})
        .finally(() => {
          this.$loading = false
        })
    },
    handleSelectAll() {
      let self = this
      this.select_all = this.select_all ? false : true
      if (!this.select_all) {
        this.download_files = []
        this.files.forEach(function (file, i) {
          file.selected = false
          self.files[i].selected = false
        })
      } else {
        this.files.forEach(function (file, i) {
          file.selected = true
          self.files[i].selected = true
          self.download_files.push(file)
        })
      }
    },
    countSelectedDownloads() {
      let count = 0
      this.download_files.forEach(function (download) {
        if (download.selected) count += 1
      })

      return count
    },
    selectToDownload(value, file) {
      let selected = null
      let index = null
      this.download_files.forEach(function (download, i) {
        if (file.id == download.id) {
          selected = download
          index = i
        }
      })

      if (index != null) {
        this.download_files.splice(index, 1)
      }

      if (!selected) {
        file.selected = value
        this.download_files.push(file)
      }
    },
    isSelected(file) {
      let found = false
      this.download_files.forEach(function (download) {
        if (file.id == download.id) {
          if (download.selected) found = true
        }
      })
      return found
    },
    filesUpdated() {
      this.$emit('updated', true)
      this.getFiles()
    },
    remove(file) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this file. This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.isConfirmed) {
          SupplierHubFile.delete({
            id: file.id
          })

          let type = "itt"
          if (this.if_pin_notice == 1) {
            type = "pin"
          }

          SupplierHubFile.delete({
              id: file.id,
              type: type
            })
            .then(() => {
              this.$toast.success('Removed successfully')
              this.getFiles()
            })
            .catch(error => {
              // Handle error accordingly
              console.error('error:', error.response.data)
              this.$toast.error('An error occurred while removing the file')
            })
        }
      })
    },
    create() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to add a file!",
          icon: 'warning',
        })
        return false
      }

      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getFiles()
    },
    getFiles() {
      this.showAdd = false

      let params = {
        page: this.meta.page,
        personal: true,
        term: this.term,
        itt_framework_id: this.itt_framework?.id,
        if_pin_notice: this.if_pin_notice,
        supplierhub_lot_id: this.lot_id
      }

      if(this.itt_framework && this.itt_framework.selected_company_id){
        params.selected_company_id = this.itt_framework.selected_company_id
      }

      if (this.user) params.uploaded_by = this.user.id
      params.load_based_company = 1
      params.company = this.$store.state?.savedUser?.company?.id
      this.loading = true
      SupplierHubFile.get({
          params: params
        })
        .then(result => {
          if (result.data.files) this.files = result.data.files
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching personal files')
          console.error('error:', error.response.data)
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
