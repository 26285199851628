<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <!-- <div class="col-md-6">
                    <label>Notice Type</label>
                    <div :class="{ 'has-error-field': v.form.notice_type.$invalid }">
                        <el-select v-model="form.notice_type" placeholder="Select" style="width: 100%;">
                            <el-option :label="'This notice is for prior information only'" :value="0"></el-option>
                            <el-option :label="'This notice aims at reducing time limits for receipt of tenders'" :value="1"></el-option>
                            <el-option :label="'This notice is a call for competition. (Interested operators must inform the contracting authority of their interest in the contract(s). The contract(s) will be awarded without publication of a further call for competition.)'" :value="2"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.notice_type.$invalid" class="error-text" >Notice Type is required</div>
                </div> -->
                <div class="col-md-6">
                    <label>Contract Type</label>
                    <div :class="{ 'has-error-field': v.form.contract_type.$invalid }">
                        <el-select v-model="form.contract_type" placeholder="Select" style="width: 100%;">
                            <el-option :label="'Works'" :value="0"></el-option>
                            <el-option :label="'Supplies'" :value="1"></el-option>
                            <el-option :label="'Services'" :value="2"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.contract_type.$invalid" class="error-text" >Contract Type is required</div>
                </div>
                <div class="col-md-12" v-if="form.notice_type == 2 && procurement_stage == FATNoticeTypes.F01.value">
                    <br/><label>Procedure Type</label>
                    <div :class="{ 'has-error-field': v.form.procedure_type.$invalid }">
                        <el-select v-model="form.procedure_type" placeholder="Select" style="width: 100%;">
                            <el-option :label="'This notice is for prior information only'" :value="0"></el-option>
                            <el-option :label="'This notice aims at reducing time limits for receipt of tenders'" :value="1"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procedure_type.$invalid" class="error-text" >Contract Type is required</div>
                </div>
                <div class="col-md-12" v-if="procurement_type == FATNoticeTypes.F02.value || procurement_stage == FATNoticeTypes.F03.value" >
                    <br/><label>Procedure Type</label>
                    <div :class="{ 'has-error-field': v.form.procedure_type.$invalid }">
                        <el-select v-model="form.procedure_type" placeholder="Select" style="width: 100%;">
                            <el-option :label="'Open procedure'" :value="0"></el-option>
                            <el-option :label="'Restricted procedure'" :value="1"></el-option>
                            <el-option :label="'Competitive procedure with negotiation'" :value="2"></el-option>
                            <el-option :label="'Competitive dialogue'" :value="3"></el-option>
                            <el-option :label="'Innovation partnership'" :value="4"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procedure_type.$invalid" class="error-text" >Contract Type is required</div>
                </div>
                <!-- <div class="col-md-12" v-if="form.notice_type == 0">
                    <br/><label>Number of contracts/objects</label>
                    <div :class="{ 'has-error-field': v.form.number_of_contracts.$invalid }">
                        <el-input v-model="form.number_of_contracts"></el-input>
                    </div>
                    <div v-if="v.form.number_of_contracts.$invalid" class="error-text" >Number of contracts/objects is required</div>
                </div> -->
                <div class="col-md-4">
                    <label>Joint Procurement</label><br/>
                    <el-checkbox v-model="form.joint_procurement" :checked="form.joint_procurement">The contract involves joint procurement (optional)</el-checkbox>
                </div>
                <div class="col-md-6" v-if="form.joint_procurement">
                    <br/><label>Number of contracting authorities responsible for the procedure</label>
                    <div :class="{ 'has-error-field': v.form.number_of_contracting_authorities.$invalid }">
                        <el-input v-model="form.number_of_contracting_authorities" :label="'Number of contracting authorities responsible for the procedure'"></el-input>
                    </div>
                    <div v-if="v.form.number_of_contracting_authorities.$invalid" class="error-text" >Number of contracting authorities is required and should be between 2 and 500</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf, numeric
     } from '@vuelidate/validators'
 
     const PRELIMINARY = 0;

     export default {
         props: ['previous_form', 'user', 'procurement_id', 'saved_form', 'procurement_stage', 'is_not_submitted', 'procurement_status', 'fat_procurement_type'],
         data() {
             return {
                form: {
                    notice_type: 0,
                    contract_type: null,
                    procedure_type: null,
                    number_of_contracts: 1,
                    joint_procurement: false,
                    number_of_contracting_authorities: null
                }
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created()  {
            if(this.saved_form) this.form = this.saved_form

        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                    console.log('asdf')
                },
                deep: true
            }
        },
        validations() {
            const number_contracting = () => {
                if(this.form.joint_procurement) {
                    if(this.form.number_of_contracting_authorities < 2 || this.form.number_of_contracting_authorities > 500 || this.form.number_of_contracting_authorities == '' || this.form.number_of_contracting_authorities == null) {
                        return false
                    }        
                        
                    return true;
                }
                return true 
            }
            let rules = {
                form: {
                    notice_type: {
                        required,
                        $autoDirty: true
                    },
                    contract_type: {
                        required,
                        $autoDirty: true
                    },
                    procedure_type: {
                        required: requiredIf(function () {
                            switch(this.procurement_stage) {
                                case this.FATNoticeTypes.F01.value:
                                    return this.form.notice_type == 2
                                case this.FATNoticeTypes.F02.value:
                                    return true
                            } 
                    }),
                        $autoDirty: true
                    },
                    number_of_contracts: {
                        required: requiredIf(function () {return this.form.notice_type == 0}),
                        $autoDirty: true
                    },
                    number_of_contracting_authorities: {
                        required: requiredIf(function () { return this.form.joint_procurement}),
                        numeric,
                        number_contracting,
                        $autoDirty: true
                    }
                }
            }
            
            return rules
        },
        methods: {
            saveForm() {
                if(this.form.notice_type != 2) {
                    this.form.procedure_type = null
                }

                if(this.form.notice_type != 0) {
                    this.form.number_of_contracts = null
                }

                if(!this.form.joint_procurement) {
                    this.form.number_of_contracting_authorities = null
                }
                
                this.$emit('save', PRELIMINARY, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            }
        }

     }
 </script>
 