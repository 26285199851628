//import Vuex from 'vuex'
import { createStore } from 'vuex'
import { LocalStorageService } from '@/services/LocalStorageService'
import * as moment from 'moment'
import createMutationsSharer from "vuex-shared-mutations";
import {
  MonthyReportCategoryIDs,
  MonthyReportCategories,
  SpecTypes,
  SocValTypes,
  ProductStatuses,
  CommonStatuses,
  CommonStatusTexts,
  InvoicePaymentStatuses,
  InvoicePaymentStatusTexts, 
  InvoiceTypes,
  InvoiceItemsStatuses,
  InvoiceItemsStatusTexts,
  BuyerRoleName,
  SupplierRoleName,
  OriginatorRoleName,
  ProcurementProjectStatuses,
  PinNoticeStatuses,
  IttStatuses,
  QaQuestionTypes,
  GuidanceEvaluationAnswerRequirements,
  GuidanceEvaluationAnswerType,
  QaSubmissionStatuses,
  ApplicationHistoryTypes,
  ProjectFilters,
  ContractYears,
  FATFormParts,
  FATNoticeTypes,
  DirectAwardReasons
} from '@/constants'

//Vue.use(Vuex)

//export default new Vuex.Store({

import Swal from 'sweetalert2';

export default createStore({
  modules: {},
  plugins: [
    createMutationsSharer({
      predicate: [
        'newNotifications', 'updateMessageCount'
      ]
    }),
  ],
  state: {
    new_message_count: 0,
    new_notifications: false,
    main_loading: false,
    sv_admin_ratings: [0,1,2,3,4,5,6,7,8,9,10],
    call_off_preview_file:
      process.env.VUE_APP_API_ROOT + '/download-call-off-preview',
    types: [],
    supplier: "Lloyd's Bank",
    loading_user: Boolean(LocalStorageService.getLoadingUser()),
    company_id: LocalStorageService.getCompanyId(),
    credential: LocalStorageService.getCredential(),
    meta: { page: 1, total: 0, pages: 1 },
    savedUser: LocalStorageService.getUser(),
    savedToken: LocalStorageService.getToken(),
    counter: 0,
    default_categories_link: [
      '',
      '',
      '',
      '',
      '',
      'banking',
      'merchant-services',
      'direct-debit',
      'payment-gateway',
      'ivr-call-masking',
      'innovation-fs'
    ],
    api_host: process.env.VUE_APP_API_ROOT,
    volume_types: ['ALL TYPES', 'ONE TIME', 'PER MONTH', 'PER YEAR'],
    bcp_statuses: ['PENDING REVIEW','ACTIVE','REJECTED'],
    project_categories: ['CLOSED FRAMEWORK', 'OPEN FRAMEWORK'],
    option_volume_types: [
      { value: null, label: 'ALL TYPES' },
      { value: 1, label: 'ONE TIME' },
      { value: 2, label: 'PER MONTH' },
      { value: 3, label: 'PER YEAR' }
    ],
    call_off_statuses: [
      'Not yet started',
      'Waiting for supplier to sign first',
      'Supplier done signing',
      'Waiting for final signature',
      'Completed',
      'Rejected',
      'Signed Document Pending',
      '',
      'Signed Document Rejected'
    ],
    supplier_call_off_statuses: [
      'Not yet started',
      'Waiting for you to sign first',
      'Done signing',
      'Waiting for final sign',
      'Completed',
      'Rejected',
      'Signed Document Pending',
      '',
      'Signed Document Rejected'
    ],
    sv_types: [
      { value: null, text: 'Select Type' },
      { value: 1, text: 'Choice' },
      { value: 2, text: 'Value' }
    ],
    units: [
      { value: null, text: 'Select Unit' },
      { value: 'time', text: 'Time' },
      { value: 'weight', text: 'Weight' },
      { value: 'height', text: 'Height' },
      { value: 'choice', text: 'Choice' },
      { value: 'speed', text: 'Speed' },
      { value: 'distance', text: 'length/distance' },
      { value: 'option', text: 'Option' },
      { value: 'volume', text: 'Volume' },
      { value: 'area', text: 'Area' },
      { value: 'digital', text: 'Digital' }
    ],
    digital_units: [
      { value: null, text: 'Select a digital Unit' },
      { value: 'bit', text: 'Bit' },
      { value: 'byte', text: 'Byte' },
      { value: 'kb', text: 'Kilbyte' },
      { value: 'mb', text: 'Megabyte' },
      { value: 'gb', text: 'Gigabyte' },
      { value: 'tb', text: 'Terabyte' }
    ],
    area_units: [
      { value: null, text: 'Select a unit' },
      { value: 'mm2', text: 'Square milimeter' },
      { value: 'cm2', text: 'Square centimeter' },
      { value: 'm2', text: 'Square meter' },
      { value: 'ha', text: 'Hectare' },
      { value: 'km2', text: 'Square kilometer' },
    ],
    weight_units: [
      { value: null, text: 'Select a unit' },
      { value: 't', text: 'tonne (t)' },
      { value: 'Kg', text: 'kilogram (kg)' },
      { value: 'g', text: 'gram (g)' },
      { value: 'mg', text: 'milligram (mg)' },
    ],
    length_units: [
      { value: null, text: 'Select a unit' },
      { value: 'mm', text: 'millimeter (mm)' },
      { value: 'cm', text: 'centimeter (cm)' },
      { value: 'm', text: 'meter (m)' },
      { value: 'km', text: 'kilometer (km)' }
    ],
    time_units: [
      { value: null, text: 'Select a unit' },
      { value: 's', text: 'second (s)' },
      { value: 'm', text: 'minute (m)' },
      { value: 'hr', text: 'hour (hr)' }
    ],
    speed_units: [
      { value: null, text: 'Select a unit' },
      { value: 'ms', text: 'meters per second (m/s)' },
      { value: 'kmh', text: 'kilometers per hour (km/h)' },
      { value: 'Kn', text: 'Knot (kn)' }
    ],
    volume_units: [
      { value: null, text: 'Select a unit' },
      { value: 'ml', text: 'Mililiter' },
      { value: 'cl', text: 'Centiliter' },
      { value: 'm3', text: 'Cubic meter' }
    ],
    contract_lengths_color: [
      'badge-info',
      'badge-info',
      'badge-info',
      'badge-info',
      'badge-info',
      'badge-info'
    ],
    contract_lengths: [
      '1 to 4 years',
      '4 to 6 years',
      '6 to 8 years',
      '8 to 10 years',
      '10+ years'
    ],
    qa_types: ['Public', 'Private'],
    supplier_hub_file_types: ['Personal', 'Common'],
    no_image: require('@/assets/no_image.png'),
    default_banner: require('@/assets/default_banner.png'),
    isUserABuyer(){
      var user = this.savedUser
      var response = false
      if (user && user.role_names) {
        if(user.role_names.includes(BuyerRoleName)) response = true
      }
      return response
    },
    isUserASupplier(){
      var user = this.savedUser
      var response = false
      if (user && user.role_names) {
        if(user.role_names.includes(SupplierRoleName)) response = true
      }
      return response
    },
    isUserAnOriginator(){
      var user = this.savedUser
      var response = false
      if (user && user.role_names) {
        if(user.role_names.includes(OriginatorRoleName)) response = true
      }
      return response
    },
    getGlobalSpecUnitLabel(unit) {
      var label = ''
      this.units.forEach(function(u) {
        if (unit == u.value) label = u.text
      })

      return label
    },
    getDecimalLength(num) {
      const numStr = String(num)
      if (numStr.includes('.')) {
        return numStr.split('.')[1].length
      }
      return 0
    },
    roundOffAmount(number, places) {
      let length = this.getDecimalLength(number)
      if (length > places) return parseFloat(number).toFixed(places)
      return number
    },
    isEmailValid(string) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(string).toLowerCase())
    },
    isDateValid(string) {
      if (!string) return false
      return string.length > 0 ? moment(new Date(string)).isValid() : false
    },
    isStringValid(string = '', min = 0, max = 999) {
      if (!string) string = ''
      return string.length > min && string.length < max
    },
    formatter: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2
    }),
    formattedAmount(amount) {
      amount = this.formatter.format(amount).replace(/^(\D+)/, '$1 ')
      return amount
    },
    checkIfNegative(amount) {
      if(Number(amount) < 0){
        return 0
      }
      return amount
    },
    formattedDate(date) {
      const parsedDate = new Date(date);
      const day = parsedDate.getDate().toString().padStart(2, '0')
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0')
      const year = parsedDate.getFullYear()

      return `${day}/${month}/${year}`
    },
    formattedDateTime(date) {
      const parsedDate = moment(date)
      return parsedDate.format('MMM DD YYYY, h:mm a')
    },
    isAllowed(name, action) {
      var response = false
      var user = this.savedUser
      if (user) {
        if (user.permissions) {
          user.permissions.forEach(function (permission) {
            if (permission.action && action && permission.module && name) {
              if (
                permission.action.toLowerCase() === action.toLowerCase() &&
                permission.module.toLowerCase() === name.toLowerCase()
              ) {
                response = true
              }
            }
           
          })
        }
      }
      return response
    },
    isViewable(name) {
      var response = false
      var user = this.savedUser
      if (user) {
        if (user.permissions) {
          user.permissions.forEach(function(permission) {
            if (
              permission.action.toLowerCase() === 'view' &&
              permission.module.toLowerCase() === name.toLowerCase()
            ) {
              response = true
            }
          })
        }
      }
      return response
    },
    // https://metricunitconversion.globefeed.com/length_conversion_table.asp
    getSVTypeLabel: function(value) {
      let label = ''
      this.sv_types.forEach(function(sv_type) {
        if (value == sv_type.value) label = sv_type.text
      })
      return label
    },
    getUnitLabel: function(value) {
      let label = ''
      this.units.forEach(function(unit) {
        if (value == unit.value) label = unit.text
      })
      return label
    },
    waitForUser: function(cb) {
      let self = this
      setTimeout(function() {
        self.counter += 1
        if (self.loading_user) {
          if (self.counter >= 20) {
            alert('Server is taking too long. ')
          } else {
            self.waitForUser(cb)
          }
        } else {
          self.counter = 0
          cb(true)
        }
      }, 1000)
    },
    isAdmin: function() {
      if (!this.savedUser) return false

      return this.savedUser.is_admin
    },
    statuses: [
      { value: null, text: 'ALL STATUS' },
      { value: 1, text: 'VALIDATED' },
      { value: 2, text: 'REVALIDATED' },
      { value: 0, text: 'UNVALIDATED' }
    ],
    product_statuses: ['PENDING REVIEW', 'PENDING REVIEW', 'APPROVED', 'REJECTED', 'DELETE REQUEST SENT'],
    product_status_types: ['warning', 'warning', 'success', 'error'],
    call_off_approval_statuses: ['PENDING REVIEW', 'APPROVED', 'REJECTED'],
    call_off_approval_status_types: ['warning','success','error'],
    plainHeaders: {
      Accept: 'application/json',
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + LocalStorageService.getToken(),
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*'
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + LocalStorageService.getToken(),
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*'
    },
    formDataHeaders: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + LocalStorageService.getToken(),
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*'
    },
    pdfHeader: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + LocalStorageService.getToken(),
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*'
    },
    MonthyReportCategoryIDs: MonthyReportCategoryIDs,
    MonthyReportCategories: MonthyReportCategories,
    SpecTypes: SpecTypes,
    SocValTypes: SocValTypes,
    getSpecTypeUnit(type, value) {
      let unt = ''
      for (const t in SpecTypes) {
        if (SpecTypes.hasOwnProperty(t) && type == t) {
          SpecTypes[t].units.forEach(function (obj) {
            if (obj.value == value) unt = obj.unit
          })
        }
      }

      return unt
    },
    getSocValTypeUnit(type, value) {
      let unt = ''
      for (const t in SocValTypes) {
        if (SocValTypes.hasOwnProperty(t) && type == t) {
          SocValTypes[t].units.forEach(function (obj) {
            if (obj.value == value) unt = obj.unit
          })
        }
      }

      return unt != 'FTE' ? unt : ''
    },
    ProductStatuses: ProductStatuses,
    commonStatuses: CommonStatuses,
    commonStatusTexts: CommonStatusTexts,
    invoiceItemsStatuses: InvoiceItemsStatuses,
    invoiceItemsStatusTexts: InvoiceItemsStatusTexts,
    invoicePaymentStatusTexts: InvoicePaymentStatusTexts,
    invoicePaymentStatuses: InvoicePaymentStatuses,
    invoiceTypes: InvoiceTypes,
    isChangingRoute: null,
    mac_address: LocalStorageService.getMacAddress(),
    getStatusClass: (status, prefix) => {
      switch (status) {
        case CommonStatuses.QUEUED:
          return `${prefix}-primary`
        case CommonStatuses.IN_PROGRESS:
          return `${prefix}-info`
        case CommonStatuses.DONE:
          return `${prefix}-success`
        case CommonStatuses.ERROR:
          return `${prefix}-danger`
        default:
          break
      }
    },
    ProcurementProjectStatuses: ProcurementProjectStatuses,
    PinNoticeStatuses: PinNoticeStatuses,
    IttStatuses: IttStatuses,
    QaQuestionTypes: QaQuestionTypes,
    GuidanceEvaluationAnswerRequirements: GuidanceEvaluationAnswerRequirements,
    GuidanceEvaluationAnswerType: GuidanceEvaluationAnswerType,
    QaSubmissionStatuses: QaSubmissionStatuses,
    ApplicationHistoryTypes: ApplicationHistoryTypes,
    ProjectFilters: ProjectFilters,
    BuyerRoleName: BuyerRoleName,
    FATFormParts: FATFormParts,
    FATNoticeTypes: FATNoticeTypes,
    ContractYears: ContractYears,
    DirectAwardReasons: DirectAwardReasons
  },
  mutations: {
    updateMessageCount: (state, value) => {
      state.new_message_count = value
    },
    newNotifications: (state, value) => {
      state.new_notifications = value
    },
    saveUser: (state, user) => {
      if (user && user.company) {
        state.company_id = user.company.id
      }
      LocalStorageService.setUser(user)
      state.savedUser = user
      state.loading_user = false

      Swal.close()
    },
    saveSupplier: (state, supplier) => {
      console.log('saving supplier to storage')
      LocalStorageService.setSupplier(supplier)
      state.savedUser = LocalStorageService.getUser()
    },
    setCredential: (state, form) => {
      console.log('saving credential to storage')
      LocalStorageService.setCredential(form)
      state.credential = LocalStorageService.getCredential()
    },
    saveCompany: (state, company) => {
      LocalStorageService.setCompany(company)
      state.savedUser = LocalStorageService.getUser()
      state.loading_user = false
    },
    saveLoadingUser: (state, loading) => {
      LocalStorageService.setLoadingUser(loading)
      state.loading_user = LocalStorageService.getLoadingUser()
    },
    saveMeta: (state, meta) => {
      state.meta = meta
    },
    saveToken: (state, token) => {
      LocalStorageService.setToken(token)
      state.savedToken = token
      state.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
      state.formDataHeaders = {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    },
    saveChangingRoute: (state, isChangingRoute) => {
      state.isChangingRoute = isChangingRoute
    }
  }
})
