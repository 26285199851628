<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h4>I.1 Name and address</h4>
                <div class="col-md-6">
                    <label>Official Name</label>
                    <div :class="{ 'has-error-field': v.form.official_name.$invalid }">
                        <el-input :label="'Official Name'" v-model="form.official_name"></el-input>
                    </div>
                    <div v-if="v.form.official_name.$invalid" class="error-text" >Official name is required</div>
                </div>
                <div class="col-md-6">
                    <label>Identification Register</label>
                    <div :class="{ 'has-error-field': v.form.identification_register.$invalid }">
                        <el-select :label="'Identification Register'" v-model="form.identification_register">
                            <el-option :label="'Companies House'" :value="0"></el-option>
                            <el-option :label="'Charity Commission (England and Wales)'" :value="1"></el-option>
                            <el-option :label="'Scottish Charity Register'" :value="2"></el-option>
                            <el-option :label="'Charity Commission for Nothern Ireland'" :value="3"></el-option>
                            <el-option :label="'NHS Organisation Data Service'" :value="4"></el-option>
                            <el-option :label="'UK Register of Learning Providers (UKPRN number)'" :value="5"></el-option>
                            <el-option :label="'Mutuals Public Register'" :value="6"></el-option>
                            <el-option :label="'Guernsey Registry'" :value="7"></el-option>
                            <el-option :label="'Jersey Financial Services Commission Registry'" :value="8"></el-option>
                            <el-option :label="'Isle of Man Companies Registry'" :value="9"></el-option>
                            <el-option :label="'Other'" :value="10"></el-option>
                            <el-option :label="'None'" :value="11"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.identification_register.$invalid" class="error-text" >Identification Register is required</div>
                    <br/>
                    <div v-if="form.identification_register==10">
                        <label>Register Name</label>
                        <div :class="{ 'has-error-field': v.form.identification_register_others.$invalid }">
                            <el-input :label="'Register Name'" v-model="form.identification_register_others" ></el-input>
                        </div>
                        <div v-if="v.form.identification_register_others.$invalid" class="error-text" >Identification Register is required</div>
                    </div>
                </div>
                <div v-if="form.identification_register==11" class="col-md-12">
                    <label>Justification</label>
                    <div :class="{ 'has-error-field': v.form.justification.$invalid }">
                        <el-radio-group v-model="form.justification">
                            <el-radio :label="0" :value="0">Partnership</el-radio>
                            <el-radio :label="1" :value="1">Sole trader</el-radio>
                            <el-radio :label="2" :value="2">Not on any register</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.justification.$invalid" class="error-text" >Justification is required</div>
                </div>
                <div class="col-md-12" v-if="form.identification_register!=11">
                        <br/><label>Identifier</label>
                        <div :class="{ 'has-error-field': v.form.identifier.$invalid }">
                            <el-input v-model="form.identifier" :label="'Identifier'"></el-input>
                        </div>
                        <div v-if="v.form.identifier.$invalid" class="error-text" >Identifier is required</div>
                    </div>
                <div class="col-md-8">
                    <br/><label>Address (optional)</label>
                        <el-input v-model="form.address" :label="'Address'"></el-input>
                </div>
                <div class="col-md-4">
                    <br/><label>Town/City</label>
                    <div :class="{ 'has-error-field': v.form.town_city.$invalid }">
                        <el-input v-model="form.town_city" :label="'Town/City'"></el-input>
                    </div>
                    <div v-if="v.form.town_city.$invalid" class="error-text" >Town/City is required</div>
                </div>
                <div class="col-md-3">
                    <br/><label>Postcode (optional)</label>
                    <el-input v-model="form.postal_code" :label="'Postcode'"></el-input>
                </div>
                <div class="col-md-3">
                    <br/><label>Country</label>
                    <div :class="{ 'has-error-field': v.form.country.$invalid }">
                        <el-select v-model="form.country" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in country_choices" :label="abbrev + ': ' + full_name" :value="abbrev">{{ full_name }}</el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.country.$invalid" class="error-text">Country is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>NUTS Code</label>
                    <div :class="{ 'has-error-field': v.form.nuts_code.$invalid }">
                        <el-select v-model="form.nuts_code" filterable clearable>
                            <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                    <span style="float: left">{{ c_values.name }}</span>
                                    <span class="secondary-selection">
                                        {{ c_abbrev }}
                                    </span>
                                </el-option>
                                    <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                        <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                            <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                <span style="float: left">{{ c1_values.name }}</span>
                                                <span class="secondary-selection">
                                                    {{ c1_abbrev }}
                                                </span>
                                            </el-option>
                                            <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                    <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                        <span style="float: left">{{ c2_values.name }}</span>
                                                        <span class="secondary-selection">
                                                            {{ c2_abbrev }}
                                                        </span>
                                                    </el-option>
                                                        <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                            <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                    <span style="float: left">{{ c3_values.name }}</span>
                                                                    <span class="secondary-selection">
                                                                        {{ c3_abbrev }}
                                                                    </span>
                                                                </el-option>
                                                                <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                    <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c4_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                </el-option-group>
                                                            </span>
                                                        </el-option-group>
                                                </span>
                                            </el-option-group>
                                        </span>
                                </el-option-group>
                            </el-option-group >
                        </el-select>
                    </div>
                    <div v-if="v.form.nuts_code.$invalid" class="error-text" >NUTS Code is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Contact Person (optional)</label>
                    <el-input v-model="form.contact_person" :label="'Contact Person'"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Email Address</label>
                    <div :class="{ 'has-error-field': v.form.email_address.$invalid }">
                        <el-input v-model="form.email_address" :label="'Email Address'"></el-input>
                    </div>
                    <div v-if="v.form.email_address.$invalid" class="error-text" >Email Address is required/invalid</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Telephone (optional)</label>
                    <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44 1234567890</label>
                    <el-input v-model="form.telephone_number" :label="'Telephone (optional)'"></el-input>
                    <div v-if="v.form.telephone_number.$invalid" class="error-text" >Phone number is invalid</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Fax (optional)</label>
                    <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44 1234567890</label>
                    <el-input v-model="form.fax_number" :label="'Fax (optional)'"></el-input>
                    <div v-if="v.form.fax_number.$invalid" class="error-text" >Fax number is invalid</div>
                </div>
                <div class="col-md-12">
                    <br/><h5>Internet address(es)</h5>
                </div>
                <div class="col-md-6">
                    <label>Main address</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                    <div :class="{ 'has-error-field': v.form.url_main.$invalid }">
                        <el-input v-model="form.url_main" :label="'Main Address'"></el-input>
                    </div>
                    <div v-if="v.form.url_main.$invalid" class="error-text" >Main address is required/invalid</div>
                </div>
                <div class="col-md-6">
                    <label>Address of the buyer profile (optional)</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                    <el-input v-model="form.url_buyer_profile" :label="'Address of the buyer profile (optional)'"></el-input>
                    <div v-if="v.form.url_buyer_profile.$invalid" class="error-text" >URL is required/invalid</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" class="mb-3" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
</template>


<script>
import { mapState } from 'vuex'
 import {
 useVuelidate
 } from '@vuelidate/core'
 import {
 required, requiredIf,
 url, email, helpers,
 numeric
 } from '@vuelidate/validators'

 const ADDITIONAL_CONTRACTING = 1.1;

 export default {
     props: ['user', 'procurement_id', 'saved_form', 'procurement_stage', 'is_not_submitted', 'nuts_code_choices', 'country_choices', 'previous_form'],
     data() {
         return {
            form: {
                official_name: null,
                identification_register: null,
                identification_register_others: null,
                identifier: null,
                justification: null,
                address: null,
                town_city: null,
                postal_code: null,
                country: null,
                nuts_code: null,
                contact_person: null,
                email_address: null,
                telephone_number: null,
                fax_number: null,
                url_main: null,
                url_buyer_profile: null,
            }
         }
     },
    setup: () => ({
         v: useVuelidate()
    }),
    created()  {
        if(this.saved_form) this.form = this.saved_form
    },
    computed: {
    ...mapState(['FATNoticeTypes', 'FATFormParts']),
    },
    watch: {
        saved_form: {
            handlers(form){
                this.form = form
            },
            deep: true
        }
    },
    validations() {
        const urlPattern = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
        const phonePattern = /^\+\d{1,3} \d{6,14}$/;
        const phoneValidation = value => !helpers.req(value) || phonePattern.test(value);

        let rules = {
            form: {
                official_name: {
                    required,
                    $autoDirty: true
                },
                identification_register: {
                    required,
                    $autoDirty: true
                },
                town_city: {
                    required,
                    $autoDirty: true
                },
                country: {
                    required,
                    $autoDirty: true
                },
                nuts_code: {
                    required,
                    $autoDirty: true
                },
                email_address: {
                    required,
                    email,
                    $autoDirty: true
                },
                url_main: {
                    required,
                    url,
                    $autoDirty: true
                },
                identification_register_others: {
                        required: requiredIf(function () {return this.form.identification_register == 10}),
                        $autoDirty: true
                },
                justification: {
                        requiredf: requiredIf(function () {return this.form.identification_register == 11}),
                        $autoDirty: true
                },
                identifier: {
                    requiredf: requiredIf(function () {return this.form.identification_register != 11}),
                    $autoDirty: true
                },
                telephone_number: {
                    phoneValidation,
                    $autoDirty: true
                },
                fax_number: {
                    phoneValidation,
                    $autoDirty: true
                },
                url_buyer_profile: {
                    url,
                    $autoDirty: true
                },
                postal_code: {
                    numeric,
                    $autoDirty: true
                }
            }
        }
        
        return rules
    },
    methods: {
        saveForm() {
            if(this.form.notice_type != 2) {
                this.form.procedure_type = null
            }

            if(this.form.notice_type != 0) {
                this.form.number_of_contracts = null
            }

            if(!this.form.joint_procurement) {
                this.form.number_of_contracting_authorities = null
            }
            
            this.$emit('save', ADDITIONAL_CONTRACTING, this.form)
        },
        getPrevious() {
            delete this.previous_form.id
            this.form = this.previous_form
        }
    }

 }
</script>
