<template>
  <div v-if="lot">

    <el-drawer title="" v-model="showPricingSetting" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true" :before-close="handleClose">
      <pin-pricing-setting-form :pin_notice="if_pin_notice ? itt_framework : null " :lot="selected"
        :pricing="selected.pin_pricing_setting" v-if="selected" @close="handleClose"></pin-pricing-setting-form>
    </el-drawer>

    <el-drawer title="" v-model="showSpecValues" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true">
      <pin-lot-supplier-specs-list :kind="kind" :lot="selected" v-if="selected"></pin-lot-supplier-specs-list>
    </el-drawer>

    <el-drawer v-model="showPricingCosts" :showClose="true" size="70%" :with-header="true" direction="rtl"
      :destroy-on-close="true" :append-to-body="true">
      <div class="container">
        <pin-supplier-costs-list :lot="selected"
          :pin_notice="if_pin_notice ? itt_framework: null"></pin-supplier-costs-list>
      </div>
    </el-drawer>

    <el-drawer title="" v-model="showAdd" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-form @close="handleSet($event)" :lot="selected" :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice"></supplierhub-lot-form>
    </el-drawer>

    <el-drawer title="" v-model="showSupplierhubSpec" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true">
      <supplierhub-lot-view @close="handleClose()" :lot="selected" :itt_framework="itt_framework"
        :pin_notice_id="pin_notice_id" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" :kind="kind"
        v-if="selected && showSupplierhubSpec"></supplierhub-lot-view>
    </el-drawer>

    <div class="row">
      <div class="col-md-12">
        <h4>{{ (selected.lot_no ? "Lot " + selected.lot_no + " - " : "") }}{{ selected.name }}
          <el-tooltip :enterable="false" placement="top-start" v-if="allowCreate">
            <template #content>
              <span>Edit </span>
            </template>
            <a style="font-size: 12px" class="link" @click="handleEdit"><i class="fa fa-edit"></i> Edit</a>
          </el-tooltip>
        </h4>
        <div v-html="selected.short_description"></div>
        <div v-html="selected.long_description"></div>
        <hr>
        <div>
          <div v-if="forSupplierValue()">
            <el-button size="small" type="primary" plain @click="handleSupplierCosts()">Pricing
              Costs</el-button>
            <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(0)">Specifications</el-button>
            <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(1)">Social
              Values</el-button>
          </div>
          <div v-else>

            <!-- <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(0)">Specifications</el-button>
            <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(1)">Social
              Values</el-button> -->

            <!-- <el-button
              v-if="itt_framework.if_user_joined && !itt_framework.is_archived && (itt_framework.project_status == 'ITT')"
              plain type="primary" @click="handleViewPinLots()" v-tooltip="'Submit values'">Submit Lot Spec/Social
              Values
              and Pricing Costs</el-button> -->

            <el-button plain type="warning" @click="handleShowPricing()"
              v-if="(pin_notice && pin_notice.project_status == 'DOC' || itt_framework && itt_framework.project_status == 'DOC')">
              <i class="fa fa-cog"></i> Set Up Pricing Configuration</el-button>
            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Specifications</span>
              </template>
              <el-button plain size="medium" type="primary" @click="handleView(0)">Specifications</el-button>
            </el-tooltip>

            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Social Values</span>
              </template>
              <el-button plain size="medium" type="primary" @click="handleView(1)">Social Values</el-button>
            </el-tooltip>
          </div>

        </div>
      </div>
      <div v-allowed="'common_files,view'" class="col-md-6">
        <common-files :itt_framework="itt_framework" :lot_id="selected.id" :if_pin_notice="if_pin_notice" :pin_notice="pin_notice"
          :update="update"></common-files>
      </div>
      <div v-allowed="'my_files,view'" class="col-md-6">
        <my-files :itt_framework="itt_framework" :lot_id="selected.id" :if_pin_notice="if_pin_notice" :pin_notice="pin_notice"
          @updated="handleUpdate"></my-files>
      </div>

      <div v-allowed="'questions,view'" class="col-md-6">
        <public-questions-with-answers-list :itt_framework="itt_framework" :lot_id="selected.id" :pin_notice="pin_notice"
          :if_pin_notice="if_pin_notice"></public-questions-with-answers-list>
      </div>

      <div class="col-md-6" v-allowed="'questions,view'">
        <my-questions-list :itt_framework="itt_framework" :lot_id="selected.id" :pin_notice="pin_notice"
          :if_pin_notice="if_pin_notice"></my-questions-list>
      </div>

    </div>
  </div>
</template>

<script>


import {
  mapState
} from 'vuex'
import PinLotSupplierSpecsList from '../../pin_notice/PinLotSupplierSpecsList.vue'
import PinPricingSettingForm from '../../pin_notice/PinPricingSettingForm.vue'
import PinSupplierCostsList from '../../pin_notice/PinSupplierCostsList.vue'
import MyQuestionsList from '../../questions_and_answers/MyQuestionsList.vue'
import PublicQuestionsWithAnswersList from '../../questions_and_answers/PublicQuestionsWithAnswersList.vue'
import CommonFiles from '../CommonFiles.vue'
import MyFiles from '../MyFiles.vue'
import SupplierhubLotForm from './SupplierhubLotForm.vue'
import SupplierhubLotView from './SupplierhubLotView.vue'

export default {
  computed: {
    ...mapState(['IttStatuses', 'ProcurementProjectStatuses', 'ProjectFilters', 'BuyerRoleName']),
    user() {
      return this.$store.state.savedUser // get state
    }
  },
  props: {
    joined: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    lot: {
      type: Object,
      required: false,
      default: null
    },
    itt_framework_id: {
      type: Number,
      required: false,
      default: null
    },
    pin_notice_id: {
      type: Number,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    },
    pin_notice: {
      type: Object,
      required: false,
      default: null
    }
  },
  components: {
    SupplierhubLotView,
    MyQuestionsList,
    PublicQuestionsWithAnswersList,
    MyFiles,
    CommonFiles,
    SupplierhubLotForm,
    PinSupplierCostsList,
    PinLotSupplierSpecsList,
    PinPricingSettingForm
 
  },
  data() {
    return {
      showSupplierhubSpec: false,
      update: 0,
      kind: 0,
      showViewSpec: false,
      selected: null,
      showAdd: false,
      showPricingCosts: false,
      showSpecValues: false,
      kind: null,
      showPricingSetting: false
    }
  },
  created: function () {
    this.selected = Object.assign({}, this.lot)
  },
  methods: {
    handleShowPricing() {
      this.showPricingSetting = true
      this.selected = Object.assign({}, this.lot)
    },
    handleSupplierSpecsView(kind) {
      this.selected = Object.assign({}, this.lot)
      this.showSpecValues = true
      this.kind = kind
    },
    handleSupplierCosts() {
      this.showPricingCosts = true
      this.selected = Object.assign({}, this.lot)
    },
    forSupplierValue() {
      return this.itt_framework && this.itt_framework.if_user_joined && !this.itt_framework.is_archived && this.itt_framework.project_status == 'ITT' && this.$store.state.isUserASupplier()
    },
    handleSet(lot) {
      this.selected = Object.assign({}, lot)
      this.showAdd = false
    },
    handleView(kind) {
      this.showSupplierhubSpec = true
      this.kind = kind
    },
    handleClose() {
      this.showPricingCosts = false
      this.showSpecValues = false
      this.showPricingSetting = false
      this.showSupplierhubSpec = false
    },
    handleUpdate() {
      this.update += 1
    },
    handleEdit() {
      let type = "itt"
      if (this.if_pin_notice == 1) type = "pin"

      if (!this.joined) {
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to edit this lot!",
          icon: 'warning',
        })
        return false
      }

      this.selected = Object.assign({}, this.lot)
      this.showAdd = true
    },
  }
}
</script>
