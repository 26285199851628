<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h4>I.1 Name and address</h4>
                <div class="col-md-6">
                    <label>Official Name</label>
                    <div :class="{ 'has-error-field': v.form.official_name.$invalid }">
                        <el-input :label="'Official Name'" v-model="form.official_name"></el-input>
                    </div>
                    <div v-if="v.form.official_name.$invalid" class="error-text" >Official name is required</div>
                </div>
                <div class="col-md-6">
                    <label>Identification Register</label>
                    <div :class="{ 'has-error-field': v.form.identification_register.$invalid }">
                        <el-select :label="'Identification Register'" v-model="form.identification_register">
                            <el-option :label="'Companies House'" :value="0"></el-option>
                            <el-option :label="'Charity Commission (England and Wales)'" :value="1"></el-option>
                            <el-option :label="'Scottish Charity Register'" :value="2"></el-option>
                            <el-option :label="'Charity Commission for Nothern Ireland'" :value="3"></el-option>
                            <el-option :label="'NHS Organisation Data Service'" :value="4"></el-option>
                            <el-option :label="'UK Register of Learning Providers (UKPRN number)'" :value="5"></el-option>
                            <el-option :label="'Mutuals Public Register'" :value="6"></el-option>
                            <el-option :label="'Guernsey Registry'" :value="7"></el-option>
                            <el-option :label="'Jersey Financial Services Commission Registry'" :value="8"></el-option>
                            <el-option :label="'Isle of Man Companies Registry'" :value="9"></el-option>
                            <el-option :label="'Other'" :value="10"></el-option>
                            <el-option :label="'None'" :value="11"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.identification_register.$invalid" class="error-text" >Identification Register is required</div>
                    <br/>
                    <div v-if="form.identification_register==10">
                        <label>Register Name</label>
                        <div :class="{ 'has-error-field': v.form.identification_register_others.$invalid }">
                            <el-input :label="'Register Name'" v-model="form.identification_register_others" ></el-input>
                        </div>
                        <div v-if="v.form.identification_register_others.$invalid" class="error-text" >Identification Register is required</div>
                    </div>
                </div>
                <div v-if="form.identification_register==11" class="col-md-12">
                    <label>Justification</label>
                    <div :class="{ 'has-error-field': v.form.justification.$invalid }">
                        <el-radio-group v-model="form.justification">
                            <el-radio :label="0" :value="0">Partnership</el-radio>
                            <el-radio :label="1" :value="1">Sole trader</el-radio>
                            <el-radio :label="2" :value="2">Not on any register</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.justification.$invalid" class="error-text" >Justification is required</div>
                </div>
                <div class="col-md-12" v-if="form.identification_register!=11">
                    <br/><label>Identifier</label>
                    <div :class="{ 'has-error-field': v.form.identifier.$invalid }">
                        <el-input v-model="form.identifier" :label="'Identifier'"></el-input>
                    </div>
                    <div v-if="v.form.identifier.$invalid" class="error-text" >Identifier is required</div>
                </div>
                <div class="col-md-8">
                    <br/><label>Address (optional)</label>
                        <el-input v-model="form.address" :label="'Address'"></el-input>
                </div>
                <div class="col-md-4">
                    <br/><label>Town/City</label>
                    <div :class="{ 'has-error-field': v.form.town_city.$invalid }">
                        <el-input v-model="form.town_city" :label="'Town/City'"></el-input>
                    </div>
                    <div v-if="v.form.town_city.$invalid" class="error-text" >Town/City is required</div>
                </div>
                <div class="col-md-3">
                    <br/><label>Postcode (optional)</label>
                    <el-input v-model="form.postal_code" :label="'Postcode'"></el-input>
                </div>
                <div class="col-md-3">
                    <br/><label>Country</label>
                    <div :class="{ 'has-error-field': v.form.country.$invalid }">
                        <el-select v-model="form.country" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in country_choices" :label="abbrev + ': ' + full_name" :value="abbrev">{{ full_name }}</el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.country.$invalid" class="error-text">Country is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>NUTS Code</label>
                    <div :class="{ 'has-error-field': v.form.nuts_code.$invalid }">
                        <el-select v-model="form.nuts_code" filterable clearable>
                            <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                    <span style="float: left">{{ c_values.name }}</span>
                                    <span class="secondary-selection">
                                        {{ c_abbrev }}
                                    </span>
                                </el-option>
                                    <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                        <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                            <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                <span style="float: left">{{ c1_values.name }}</span>
                                                <span class="secondary-selection">
                                                    {{ c1_abbrev }}
                                                </span>
                                            </el-option>
                                            <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                    <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                        <span style="float: left">{{ c2_values.name }}</span>
                                                        <span class="secondary-selection">
                                                            {{ c2_abbrev }}
                                                        </span>
                                                    </el-option>
                                                        <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                            <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                    <span style="float: left">{{ c3_values.name }}</span>
                                                                    <span class="secondary-selection">
                                                                        {{ c3_abbrev }}
                                                                    </span>
                                                                </el-option>
                                                                <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                    <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c4_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                </el-option-group>
                                                            </span>
                                                        </el-option-group>
                                                </span>
                                            </el-option-group>
                                        </span>
                                </el-option-group>
                            </el-option-group >
                        </el-select>
                    </div>
                    <div v-if="v.form.nuts_code.$invalid" class="error-text" >NUTS Code is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Contact Person (optional)</label>
                    <el-input v-model="form.contact_person" :label="'Contact Person'"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Email Address</label>
                    <div :class="{ 'has-error-field': v.form.email_address.$invalid }">
                        <el-input v-model="form.email_address" :label="'Email Address'"></el-input>
                    </div>
                    <div v-if="v.form.email_address.$invalid" class="error-text" >Email Address is required/invalid</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Telephone (optional)</label>
                    <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44 1234567890</label>
                    <el-input v-model="form.telephone_number" :label="'Telephone (optional)'"></el-input>
                    <div v-if="v.form.telephone_number.$invalid" class="error-text" >Phone number is invalid</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Fax (optional)</label>
                    <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44 1234567890</label>
                    <el-input v-model="form.fax_number" :label="'Fax (optional)'"></el-input>
                    <div v-if="v.form.fax_number.$invalid" class="error-text" >Fax number is invalid</div>
                </div>
                <div class="col-md-12">
                    <br/><h5>Internet address(es)</h5>
                </div>
                <div class="col-md-6">
                    <label>Main address</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                    <div :class="{ 'has-error-field': v.form.url_main.$invalid }">
                        <el-input v-model="form.url_main" :label="'Main Address'"></el-input>
                    </div>
                    <div v-if="v.form.url_main.$invalid" class="error-text" >Main address is required/invalid</div>
                </div>
                <div class="col-md-6">
                    <label>Address of the buyer profile (optional)</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                    <el-input v-model="form.url_buyer_profile" :label="'Address of the buyer profile (optional)'"></el-input>
                    <div v-if="v.form.url_buyer_profile.$invalid" class="error-text" >URL is invalid</div>
                </div>
                <div class="col-md-12">
                    <hr/>
                    <br/><h4>I.2. Joint Procurement</h4>
                    <div v-if="preliminary.joint_procurement">
                        <label>The contract involves joint procurement (as specified in preliminary questions)</label>
                        <span v-if="preliminary.joint_procurement">
                            <br/><label>In the case of joint procurement involving different countries, state applicable national procurement law (optional)</label>
                            <el-input v-model="form.procurement_involve_different_countries"></el-input>
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <el-checkbox v-model="form.awarded_by_purchasing_body" :checked="form.awarded_by_purchasing_body" :label="'The contract is awarded by a central purchasing body (optional)'">The contract is awarded by a central purchasing body (optional)</el-checkbox>
                </div>
                <br/><h5 class="col-md-12">I.3. Communication</h5>
                <div class="col-md-6">
                    <br/><label>How are the procurement documents being made available? {{ fat_procurement_type == FATNoticeTypes.F01.value && '(optional)'}}</label>
                    <div :class="{ 'has-error-field': v.form.procurement_documents_availability.$invalid }">
                        <el-select v-model="form.procurement_documents_availability" clearable>
                            <el-option :label="'The procurement documents are available for unrestricted and full direct access, free of charge, at'" :value="0"></el-option>
                            <el-option :label="'Access to the procurement documents is restricted. Further information can be obtained at'" :value="1"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procurement_documents_availability.$invalid" class="error-text" >URL is required/invalid</div>
                </div>
                <div class="col-md-6" v-if="form.procurement_documents_availability != null">
                    <!-- <el-input v-model="" -->
                     <br/><label>URL</label>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                        <div :class="{ 'has-error-field': v.form.procurement_documents_availability_site.$invalid }">
                            <el-input v-model="form.procurement_documents_availability_site"></el-input>
                            </div>
                     <div v-if="v.form.procurement_documents_availability_site.$invalid" class="error-text" >URL is required/invalid</div>
                </div>

                <div class="col-md-6">
                    <br/><label>Additional information can be obtained from</label>
                    <div :class="{ 'has-error-field': v.form.procurement_documents_addl_info.$invalid }">
                        <el-select v-model="form.procurement_documents_addl_info" :label="'Additional information can be obtained from'" clearable>
                            <el-option :label="'The above-mentioned address'" :value="0"></el-option>
                            <el-option :label="'Another address'" :value="1"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procurement_documents_addl_info.$invalid" class="error-text" >Additional information is required</div>
                </div>
                <div v-if="form.procurement_documents_addl_info == 1" class="card-body mt-2 row">
                    <div class="col-md-6">
                        <label>Official Name</label>
                        <div :class="{ 'has-error-field': v.form.official_name_addl.$invalid }">
                            <el-input :label="'Official Name'" v-model="form.official_name_addl"></el-input>
                        </div>
                        <div v-if="v.form.official_name_addl.$invalid" class="error-text" >Official name is required</div>
                    </div>
                    <div class="col-md-6">
                        <label>Address (optional)</label>
                            <el-input v-model="form.address_addl" :label="'Address'"></el-input>
                    </div>
                    <div class="col-md-3">
                        <br/><label>Town/City</label>
                        <div :class="{ 'has-error-field': v.form.town_city_addl.$invalid }">
                            <el-input v-model="form.town_city_addl" :label="'Town/City'"></el-input>
                        </div>
                        <div v-if="v.form.town_city_addl.$invalid" class="error-text" >Town/City is required</div>
                    </div>
                    <div class="col-md-3">
                        <br/><label>Postcode (optional)</label>
                        <el-input v-model="form.postal_code_addl" :label="'Postcode'"></el-input>
                    </div>
                    <div class="col-md-3">
                        <br/><label>Country</label>
                        <div :class="{ 'has-error-field': v.form.country_addl.$invalid }">
                            <el-select v-model="form.country_addl" filterable clearable>
                                <el-option v-for="(full_name, abbrev) in country_choices" :label="abbrev + ': ' + full_name" :value="abbrev">{{ full_name }}</el-option>
                            </el-select>
                        </div>
                        <div v-if="v.form.country_addl.$invalid" class="error-text">Country is required</div>
                    </div>
                    <div class="col-md-3">
                        <br/><label>NUTS Code</label>
                        <div :class="{ 'has-error-field': v.form.nuts_code_addl.$invalid }">
                            <el-select v-model="form.nuts_code_addl" filterable clearable>
                                <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                    <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                        <span style="float: left">{{ c_values.name }}</span>
                                        <span class="secondary-selection">
                                            {{ c_abbrev }}
                                        </span>
                                    </el-option>
                                        <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                            <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                                <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                    <span style="float: left">{{ c1_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c1_abbrev }}
                                                    </span>
                                                </el-option>
                                                <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                    <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                        <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                            <span style="float: left">{{ c2_values.name }}</span>
                                                            <span class="secondary-selection">
                                                                {{ c2_abbrev }}
                                                            </span>
                                                        </el-option>
                                                            <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                                <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                    <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c3_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                    <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                        <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                            <span style="float: left">{{ c4_values.name }}</span>
                                                                            <span class="secondary-selection">
                                                                                {{ c3_abbrev }}
                                                                            </span>
                                                                        </el-option>
                                                                    </el-option-group>
                                                                </span>
                                                            </el-option-group>
                                                    </span>
                                                </el-option-group>
                                            </span>
                                    </el-option-group>
                                </el-option-group >
                            </el-select>
                        </div>
                        <div v-if="v.form.nuts_code_addl.$invalid" class="error-text" >NUTS Code is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Contact Person (optional)</label>
                        <el-input v-model="form.contact_person_addl" :label="'Contact Person'"></el-input>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Email Address</label>
                        <div :class="{ 'has-error-field': v.form.email_address_addl.$invalid }">
                            <el-input v-model="form.email_address_addl" :label="'Email Address'"></el-input>
                        </div>
                        <div v-if="v.form.email_address_addl.$invalid" class="error-text" >Email Address is required/invalid</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Telephone (optional)</label>
                        <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44&nbsp;1234567890</label>
                        <el-input v-model="form.telephone_number_addl" :label="'Telephone (optional)'"></el-input>
                        <div v-if="v.form.telephone_number_addl.$invalid" class="error-text" >Phone number is invalid</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Fax (optional)</label>
                        <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44&nbsp;1234567890</label>
                        <el-input v-model="form.fax_number_addl" :label="'Fax (optional)'"></el-input>
                        <div v-if="v.form.fax_number_addl.$invalid" class="error-text" >Fax number is invalid</div>
                    </div>
                    <div class="col-md-12">
                        <br/><h6>Internet address(es)</h6>
                    </div>
                    <div class="col-md-6">
                        <label>Main address</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                        <div :class="{ 'has-error-field': v.form.url_main_addl.$invalid }">
                            <el-input v-model="form.url_main_addl" :label="'Main Address'"></el-input>
                        </div>
                        <div v-if="v.form.url_main_addl.$invalid" class="error-text" >Main address URL is required/invalid</div>
                    </div>
                    <div class="col-md-6">
                        <label>Address of the buyer profile (optional)</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                        <el-input v-model="form.url_buyer_profile_addl" :label="'Address of the buyer profile (optional)'"></el-input>
                        <div v-if="v.form.url_buyer_profile_addl.$invalid" class="error-text" >URL is invalid</div>
                    </div>
                </div>

                <div v-if="fat_procurement_type == FATNoticeTypes.F02.value">
                    <div class="col-md-6"><br/><label>Tenders or requests to participate must be submitted</label>
                        <br/><label style="color: #A9A9A9">Select electronic submission, postal submission or both</label>
                        <br/><div :class="{ 'has-error-field': v.form.submission_type.$invalid }">
                            <el-checkbox :label="'Electronically via (optional)'" v-model="form.submitted_electronically" :checked="form.submitted_electronically"></el-checkbox>
                        </div>
                        <div v-if="v.form.submission_type.$invalid" class="error-text" >Select at least one submission type</div>
                    </div>
                    <div class="col-md-6" v-if="form.submitted_electronically">
                        <br/><label>URL</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <br/><label style="color: #A9A9A9">For example, https://www.example.com/</label>
                        <div :class="{ 'has-error-field': v.form.submitted_electronically_url.$invalid }">
                            <el-input :label="'URL'" v-model="form.submitted_electronically_url"></el-input>
                        </div>
                        <div v-if="v.form.submitted_electronically_url.$invalid" class="error-text" >URL is required/invalid</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>And/or by post (option)</label>
                        <br/><div :class="{ 'has-error-field': v.form.submission_type.$invalid }">
                            <el-radio-group v-model="form.submitted_by_post">
                                <el-radio :label="0" :value="0">The above-mentioned address</el-radio>
                                <el-radio :label="1" :value="1">Another address</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.submission_type.$invalid" class="error-text" >Select at least one submission type</div>
                        <br/><el-button type="primary" @click="form.submitted_by_post = null" v-if="form.submitted_by_post != null">Clear</el-button>
                    </div>
                    <div v-if="form.submitted_by_post == 1" class="card-body mt-2 row">
                        <div class="col-md-6">
                            <label>Official Name</label>
                            <div :class="{ 'has-error-field': v.form.official_name_addl_post.$invalid }">
                                <el-input :label="'Official Name'" v-model="form.official_name_addl_post"></el-input>
                            </div>
                            <div v-if="v.form.official_name_addl_post.$invalid" class="error-text" >Official name is required</div>
                        </div>
                        <div class="col-md-6">
                            <label>Address (optional)</label>
                                <el-input v-model="form.address_addl_post" :label="'Address'"></el-input>
                        </div>
                        <div class="col-md-3">
                            <br/><label>Town/City</label>
                            <div :class="{ 'has-error-field': v.form.town_city_addl_post.$invalid }">
                                <el-input v-model="form.town_city_addl_post" :label="'Town/City'"></el-input>
                            </div>
                            <div v-if="v.form.town_city_addl_post.$invalid" class="error-text" >Town/City is required</div>
                        </div>
                        <div class="col-md-3">
                            <br/><label>Postcode (optional)</label>
                            <el-input v-model="form.postal_code_addl_post" :label="'Postcode'"></el-input>
                        </div>
                        <div class="col-md-3">
                            <br/><label>Country</label>
                            <div :class="{ 'has-error-field': v.form.country_addl_post.$invalid }">
                                <el-select v-model="form.country_addl_post" filterable clearable>
                                    <el-option v-for="(full_name, abbrev) in country_choices" :label="abbrev + ': ' + full_name" :value="abbrev">{{ full_name }}</el-option>
                                </el-select>
                            </div>
                            <div v-if="v.form.country_addl_post.$invalid" class="error-text">Country is required</div>
                        </div>
                        <div class="col-md-3">
                            <br/><label>NUTS Code</label>
                            <div :class="{ 'has-error-field': v.form.nuts_code_addl_post.$invalid }">
                                <el-select v-model="form.nuts_code_addl_post" filterable clearable>
                                    <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                        <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                            <span style="float: left">{{ c_values.name }}</span>
                                            <span class="secondary-selection">
                                                {{ c_abbrev }}
                                            </span>
                                        </el-option>
                                            <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                                <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                                    <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                        <span style="float: left">{{ c1_values.name }}</span>
                                                        <span class="secondary-selection">
                                                            {{ c1_abbrev }}
                                                        </span>
                                                    </el-option>
                                                    <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                        <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                            <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                                <span style="float: left">{{ c2_values.name }}</span>
                                                                <span class="secondary-selection">
                                                                    {{ c2_abbrev }}
                                                                </span>
                                                            </el-option>
                                                                <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                                    <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                        <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                            <span style="float: left">{{ c3_values.name }}</span>
                                                                            <span class="secondary-selection">
                                                                                {{ c3_abbrev }}
                                                                            </span>
                                                                        </el-option>
                                                                        <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                            <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                                <span style="float: left">{{ c4_values.name }}</span>
                                                                                <span class="secondary-selection">
                                                                                    {{ c3_abbrev }}
                                                                                </span>
                                                                            </el-option>
                                                                        </el-option-group>
                                                                    </span>
                                                                </el-option-group>
                                                        </span>
                                                    </el-option-group>
                                                </span>
                                        </el-option-group>
                                    </el-option-group >
                                </el-select>
                            </div>
                            <div v-if="v.form.nuts_code_addl_post.$invalid" class="error-text" >NUTS Code is required</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Contact Person (optional)</label>
                            <el-input v-model="form.contact_person_addl_post" :label="'Contact Person'"></el-input>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Email Address</label>
                            <div :class="{ 'has-error-field': v.form.email_address_addl_post.$invalid }">
                                <el-input v-model="form.email_address_addl_post" :label="'Email Address'"></el-input>
                            </div>
                            <div v-if="v.form.email_address_addl_post.$invalid" class="error-text" >Email Address is required/invalid</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Telephone (optional)</label>
                            <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44&nbsp;1234567890</label>
                            <el-input v-model="form.telephone_number_addl_post" :label="'Telephone (optional)'"></el-input>
                            <div v-if="v.form.telephone_number_addl_post.$invalid" class="error-text" >Phone number is invalid</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Fax (optional)</label>
                            <br/><label style="color: #A9A9A9">Must start with the country code followed by a space, for example +44&nbsp;1234567890</label>
                            <el-input v-model="form.fax_number_addl_post" :label="'Fax (optional)'"></el-input>
                            <div v-if="v.form.fax_number_addl_post.$invalid" class="error-text" >Fax number is invalid</div>
                        </div>
                        <div class="col-md-12">
                            <br/><h6>Internet address(es)</h6>
                        </div>
                        <div class="col-md-6">
                            <label>Main address</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                            <div :class="{ 'has-error-field': v.form.url_main_addl_post.$invalid }">
                                <el-input v-model="form.url_main_addl_post" :label="'Main Address'"></el-input>
                            </div>
                            <div v-if="v.form.url_main_addl_post.$invalid" class="error-text" >URL is required/invalid</div>
                        </div>
                        <div class="col-md-6">
                            <label>Address of the buyer profile (optional)</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                            <el-input v-model="form.url_buyer_profile_addl_post" :label="'Address of the buyer profile (optional)'"></el-input>
                            <div v-if="v.form.url_buyer_profile_addl_post.$invalid" class="error-text" >URL is invalid</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <br/>
                    <el-checkbox :label="'Electronically via (optional)'" v-model="form.requires_tools_and_devices" :checked="form.requires_tools_and_devices">Electronic communication requires the use of tools and devices that are not generally available. Unrestricted and full direct access to these tools and devices is possible, free of charge (optional)</el-checkbox>
                    <br/>
                    <div v-if="form.requires_tools_and_devices">
                        <label>URL</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label style="color: #A9A9A9">For example, https://www.example.com/</label>
                        <div :class="{ 'has-error-field': v.form.requires_tools_and_devices_url.$invalid }">
                            <el-input  v-model="form.requires_tools_and_devices_url"></el-input>
                        </div>
                        <div v-if="v.form.requires_tools_and_devices_url.$invalid" class="error-text" >URL is required/invalid</div>
                    </div>
                </div>

                <div class="col-md-12">
                    <br/><label>I.4. Type of the contracting authority</label>
                    <div :class="{ 'has-error-field': v.form.contracting_authority_type.$invalid }">
                        <el-select v-model="form.contracting_authority_type">
                            <el-option :label="'Ministry or any other national or federal authority, including their regional or local subdivisions'" :value="0"></el-option>
                            <el-option :label="'National or federal agency/office'" :value="1"></el-option>
                            <el-option :label="'Regional or local authority'" :value="2"></el-option>
                            <el-option :label="'Regional or local agency/office'" :value="3"></el-option>
                            <el-option :label="'Body governed by public law'" :value="4"></el-option>
                            <el-option :label="'Other type'" :value="5"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.contracting_authority_type.$invalid" class="error-text" >Contracting authority is required</div>
                    <div v-if="form.contracting_authority_type == 5">
                        <br/><label>Please Specify</label>
                        <div :class="{ 'has-error-field': v.form.contracting_authority_type_other.$invalid }">
                            <el-input v-model="form.contracting_authority_type_other" ></el-input>
                        </div>
                        <div v-if="v.form.contracting_authority_type_other.$invalid" class="error-text" >Other contracting authority is required</div>
                    </div>
                </div>

                <div class="col-md-12">
                    <br/><label>Main Activity</label>
                    <div :class="{ 'has-error-field': v.form.main_activity.$invalid }">
                        <el-select v-model="form.main_activity">
                            <el-option :label="'General public services'" :value="0"></el-option>
                            <el-option :label="'Defence'" :value="1"></el-option>
                            <el-option :label="'Public order and safety'" :value="2"></el-option>
                            <el-option :label="'Environment'" :value="3"></el-option>
                            <el-option :label="'Economic and financial affairs'" :value="4"></el-option>
                            <el-option :label="'Health'" :value="5"></el-option>
                            <el-option :label="'Housing and community amenities'" :value="6"></el-option>
                            <el-option :label="'Social protection'" :value="7"></el-option>
                            <el-option :label="'Recreation, culture and religion'" :value="8"></el-option>
                            <el-option :label="'Education'" :value="9"></el-option>
                            <el-option :label="'Other type'" :value="10"></el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.main_activity.$invalid" class="error-text" >Main activity is required</div>

                    <div v-if="form.main_activity == 10">
                        <br/><label>Please Specify</label>
                        <div :class="{ 'has-error-field': v.form.main_activity_other.$invalid }">
                            <el-input v-model="form.main_activity_other"></el-input>
                        </div>
                        <div v-if="v.form.main_activity_other.$invalid" class="error-text" >Other main activity is required</div>
                    </div>
                </div>
            </div>
        </el-card>
   </div>
   <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
</template>

<style>
    .secondary-selection {
        float: right;
        color: var(--el-text-color-secondary);
        font-size: 13px;
    }
</style>

 <script>
    import { mapState } from 'vuex'
    import {
    useVuelidate
    } from '@vuelidate/core'
    import {
    required, requiredIf,
    url, email, helpers,
    numeric
    } from '@vuelidate/validators'
 
     const SECTION_ONE = 1;
     export default {
        props: ['previous_form', 'user', 'procurement_id', 'saved_form', 'nuts_code_choices', 'country_choices', 'procurement_stage', 'is_not_submitted', 'preliminary', 'fat_procurement_type'],
        data() {
             return {
                form: {
                    official_name: null,
                    identification_register: null,
                    identification_register_others: null,
                    identifier: null,
                    justification: null,
                    address: null,
                    town_city: null,
                    postal_code: null,
                    country: null,
                    nuts_code: null,
                    contact_person: null,
                    email_address: null,
                    telephone_number: null,
                    fax_number: null,
                    url_main: null,
                    url_buyer_profile: null,
                    procurement_documents_availability: null,
                    procurement_documents_availability_site: null,
                    procurement_involve_different_countries: null,
                    procurement_documents_addl_info: null,
                    procurement_documents_addl_info_address: null,
                    contracting_authority_type: null,
                    contracting_authority_type_other: null,
                    main_activity: null,
                    awarded_by_purchasing_body: null,
                    main_activity_other: null,
                    requires_tools_and_devices: null,
                    official_name_addl: null,
                    address_addl: null,
                    town_city_addl: null,
                    postal_code_addl: null,
                    country_addl: null,
                    nuts_code_addl: null,
                    contact_person_addl: null,
                    email_address_addl: null,
                    telephone_number_addl: null,
                    fax_number_addl: null,
                    url_main_addl: null,
                    url_buyer_profile_addl: null,
                    requires_tools_and_devices_url: null,
                    // f02
                    submitted_electronically: null,
                    submitted_electronically_url: null,
                    submitted_by_post: null,
                    official_name_addl_post: null,
                    address_addl_post: null,
                    town_city_addl_post: null,
                    postal_code_addl_post: null,
                    country_addl_post: null,
                    nuts_code_addl_post: null,
                    contact_person_addl_post: null,
                    email_address_addl_post: null,
                    telephone_number_addl_post: null,
                    fax_number_addl_post: null,
                    url_main_addl_post: null,
                    url_buyer_profile_addl_post: null,

                }
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        mounted()  {
            if(this.saved_form) {
                this.form = this.saved_form
                if(this.form.awarded_by_purchasing_body != null) this.form.awarded_by_purchasing_body = this.convertBool(this.form.awarded_by_purchasing_body)
                if(this.form.requires_tools_and_devices != null) this.form.requires_tools_and_devices = this.convertBool(this.form.requires_tools_and_devices)
            }
            else this.initData()

        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts']),
        },
        validations() {
            const urlPattern = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
            const phonePattern = /^\+\d{1,3} \d{6,14}$/;
            const phoneValidation = value => !helpers.req(value) || phonePattern.test(value);

            let rules = {
                form: {
                    official_name: {
                    required,
                    $autoDirty: true
                    },
                    identification_register: {
                        required,
                        $autoDirty: true
                    },
                    identifier: {
                        requiredf: requiredIf(function () {return this.form.identification_register != 11}),
                        $autoDirty: true
                    },
                    town_city: {
                        required,
                        $autoDirty: true
                    },
                    country: {
                        required,
                        $autoDirty: true,
                    },
                    nuts_code: {
                        required,
                        $autoDirty: true
                    },
                    email_address: {
                        required,
                        email,
                        $autoDirty: true
                    },
                    url_main: {
                        required,
                        url,
                        $autoDirty: true
                    },
                    procurement_documents_addl_info: {
                        required,
                        $autoDirty: true
                    },
                    contracting_authority_type: {
                        required,
                        $autoDirty: true
                    },
                    main_activity: {
                        required,
                        $autoDirty: true
                    },
                    identification_register_others: {
                        required: requiredIf(function () {return this.form.identification_register == 10}),
                        $autoDirty: true
                    },
                    contracting_authority_type_other: {
                        required: requiredIf(function () {return this.form.contracting_authority_type == 5}),
                        $autoDirty: true
                    },
                    main_activity_other: {
                        required: requiredIf(function () {return this.form.main_activity == 10}),
                        $autoDirty: true
                    },
                    requires_tools_and_devices_url: {
                        required: requiredIf(function () {return this.form.requires_tools_and_devices}),
                        url,
                        $autoDirty: true
                    },
                    procurement_documents_availability_site: {
                        required: requiredIf(function () {return this.form.procurement_documents_availability}),
                        url,
                        $autoDirty: true
                    },
                    official_name_addl: {
                        required: requiredIf(function () {return this.form.procurement_documents_addl_info == 1}),
                        $autoDirty: true
                        },
                    town_city_addl: {
                        required: requiredIf(function () {return this.form.procurement_documents_addl_info == 1}),
                        $autoDirty: true
                    },
                    country_addl: {
                        required: requiredIf(function () {return this.form.procurement_documents_addl_info == 1}),
                        $autoDirty: true
                    },
                    nuts_code_addl: {
                        required: requiredIf(function () {return this.form.procurement_documents_addl_info == 1}),
                        $autoDirty: true
                    },
                    email_address_addl: {
                        required: requiredIf(function () {return this.form.procurement_documents_addl_info == 1}),
                        email,
                        $autoDirty: true
                    },
                    url_main_addl: {
                        required: requiredIf(function () {return this.form.procurement_documents_addl_info == 1}),
                        url,
                        $autoDirty: true
                    },
                    justification: {
                        required: requiredIf(function () {return this.form.identification_register == 11}),
                        $autoDirty: true
                    },
                    submitted_electronically_url: {
                        required: requiredIf(function () {return this.form.submitted_electronically == true}),
                        url,
                        $autoDirty: true
                    },
                    official_name_addl_post: {
                        required: requiredIf(function () { return this.form.submitted_by_post == 1}),
                        $autoDirty: true
                    },
                    town_city_addl_post: {
                        required: requiredIf(function () { return this.form.submitted_by_post == 1}),
                        $autoDirty: true
                    },
                    country_addl_post: {
                        required: requiredIf(function () { return this.form.submitted_by_post == 1}),
                        $autoDirty: true
                    },
                    nuts_code_addl_post: {
                        required: requiredIf(function () { return this.form.submitted_by_post == 1}),
                        $autoDirty: true
                    },
                    email_address_addl_post: {
                        required: requiredIf(function () { return this.form.submitted_by_post == 1}),
                        email,
                        $autoDirty: true
                    },
                    url_main_addl_post: {
                        required: requiredIf(function () { return this.form.submitted_by_post == 1}),
                        url,
                        $autoDirty: true
                    },
                    procurement_documents_availability: {
                        required: requiredIf(function () { return this.fat_procurement_type == this.FATNoticeTypes.F02.value}),
                        $autoDirty: true
                    },
                    submission_type: {
                        required: requiredIf(function () { return this.fat_procurement_type == this.FATNoticeTypes.F02.value && (!this.form.submitted_electronically && this.form.submitted_by_post == null)}),
                        $autoDirty: true
                    },
                    //optional
                    url_buyer_profile: {
                        url,
                        $autoDirty: true
                    },
                    url_buyer_profile_addl: {
                        url,
                        $autoDirty: true
                    },
                    url_buyer_profile_addl_post: {
                        url,
                        $autoDirty: true
                    },
                    telephone_number: {
                        phoneValidation,
                        $autoDirty: true
                    },
                    fax_number: {
                        phoneValidation,
                        $autoDirty: true
                    },
                    telephone_number_addl: {
                        phoneValidation,
                        $autoDirty: true
                    },
                    fax_number_addl: {
                        phoneValidation,
                        $autoDirty: true
                    },
                    fax_number_addl_post: {
                        phoneValidation,
                        $autoDirty: true
                    },
                    telephone_number_addl_post: {
                        phoneValidation,
                        $autoDirty: true
                    },
                }
            }

            return rules
        },
        methods: {
            initData() {
                let companyDetails = this.user.company;

                if(this.user) {
                    this.form.official_name = companyDetails.name
                    this.form.address = companyDetails.address1
                    this.form.town_city = companyDetails.city
                    this.form.postal_code = companyDetails.zip
                    this.form.country = companyDetails.country
                    this.form.email_address = companyDetails.email_address
                    
                }
            },
            convertBool(int) {
                switch(int) {
                    case 1:
                        case true: return true
                    default: return false
                }
            },
            getPrevious() {
                delete this.previous_form.id

                this.form = this.previous_form;

                if(this.form.awarded_by_purchasing_body != null) this.form.awarded_by_purchasing_body = this.convertBool(this.form.awarded_by_purchasing_body)
                if(this.form.requires_tools_and_devices != null) this.form.requires_tools_and_devices = this.convertBool(this.form.requires_tools_and_devices)
            },
            saveForm() {
                if(this.form.identification_register != 10) this.form.identification_register_others = null
                if(this.form.procurement_documents_addl_info != 1) this.form.procurement_documents_addl_info_address = null
                if(this.form.contracting_authority_type != 5) this.form.contracting_authority_type_other = null
                if(this.form.main_activity != 10) this.form.main_activity_other = null

                this.$emit('save', SECTION_ONE, this.form)
            },
        }
     }
 </script>
 