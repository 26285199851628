import api from './api'

const API = '/api/find_a_tender'

export const FindATender = {
  get: (params) => {
    return api.get(API, {params})
  },
  getStaticData: () => {
    return api.get(API + '/fetch_static_data')
  },
  create: (form_type,params) => {
    return api.create(API + '/'+ form_type, params)
  },
  getAllForms: (id, procurement_type, get_previous = 0) => {
    return api.get(API + '/get_all_forms/'+ id + "/" + procurement_type + '/' + get_previous)
  },
submitToTender: (id, form_type, params) => {
    return api.get(API + '/submit_to_tender/'+ id + '/' + form_type, {params})
  },
  update: (id, params) => {
    return api.update(`${API}/${id}`, params)
  },
  delete: (id) => {
    return api.delete(`${API}/${id}`)
  }
}
