<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3>Section II. Object</h3>
                <h4 class="col-md-12">II.1. Scope of the procurement</h4>
                <div class="col-md-6">
                    <br/><label>Title</label>
                    <div :class="{ 'has-error-field': v.form.procurement_title.$invalid }">
                        <el-input :label="'Title'" v-model="form.procurement_title"></el-input>
                    </div>
                    <div v-if="v.form.procurement_title.$invalid" class="error-text" >Title is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Reference Number (optional)</label>
                    <el-input :label="'Reference Number (optional)'" v-model="form.procurement_reference_number"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>II.1.2. Main CPV Code</label>
                    <br/><label style="color: #A9A9A9">Must start with 0 to 44 or 48 for suppliers contracts, 45 for works, or 49 to 98 for services</label>
                    <div :class="{ 'has-error-field': v.form.procurement_cpv_code.$invalid }">
                        <!-- <el-select v-model="form.procurement_cpv_code" filterable clearable>
                            <el-option v-for="(description, code) in cpv_code_choices[preliminary.contract_type]" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select> -->
                        <el-select v-model="form.procurement_cpv_code" filterable clearable remote :remote-method="searchCPV">
                            <!-- <div v-for="categories in cpv_code_choices"> -->
                                <!-- <el-option v-for="(description, code) in categories" :key="code" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option> -->
                                <el-option
                                    v-for="item in optionsCpv"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            <!-- </div> -->
                        </el-select>
                    </div>
                    <div v-if="v.form.procurement_cpv_code.$invalid" class="error-text" >CPV code is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>II.1.3. Type of contract</label>
                    <br/><label style="color: #A9A9A9">You can change the contract type in the preliminary questions</label>
                    <p>{{ contractTypeTranslation(preliminary.contract_type) }}</p>
                </div>
                <div class="col-md-12" v-if="form.procurement_cpv_code">
                    <br/><label>Supplementary Code (optional)</label>
                        <el-select v-model="form.procurement_supplementary_code" filterable clearable multiple>
                            <span v-for="(parts, header) in supplementary_code_choices">
                                <el-option-group :label="header">
                                    <el-option-group v-for="(sub_parts, sub_header) in parts" :label="sub_header">
                                        <el-option v-for="(description, code) in sub_parts" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                                    </el-option-group>
                                </el-option-group>
                            </span>
                            <el-option  :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                </div>
                <div class="col-md-12">
                    <br/><label>II.1.4. Short Description</label>
                    <div :class="{ 'has-error-field': v.form.procurement_short_description.$invalid }">
                        <el-input type="textarea" :rows="5" :label="'Short Description'" v-model="form.procurement_short_description"></el-input>
                    </div>
                    <div v-if="v.form.procurement_short_description.$invalid" class="error-text" >Short Description is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>II.1.5. Estimated total value (excluding VAT) (optional)</label>
                    <el-input :label="'For example, 200000 or 417709.28. For framework agreements or dynamic purchasing systems - estimated total maximum value ofor the entire duration of the framework agreement or dynamic purchasing system.'" v-model="form.procurement_estimated_total_value"></el-input>
                    <div v-if="v.form.procurement_estimated_total_value.$invalid" class="error-text" >Invalid format</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Currency</label>
                    <div :class="{ 'has-error-field': v.form.procurement_currency.$invalid }">
                        <el-select v-model="form.procurement_currency" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in currency_choices" :key="abbrev" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procurement_currency.$invalid" class="error-text" >Currency is required</div>
                </div>
                <div class="col-md-12">
                    <br/><label>II.1.6. Information about lots</label>
                    <br/><label>This contract is divided into lots.</label><br/>
                    <div :class="{ 'has-error-field': v.form.procurement_divided_into_lots.$invalid }">
                        <el-radio-group v-model="form.procurement_divided_into_lots">
                            <el-radio :label="1" :value="1">Yes</el-radio>
                            <el-radio :label="0" :value="0">No</el-radio>
                        </el-radio-group>
                    </div> 
                    <div v-if="v.form.procurement_divided_into_lots.$invalid" class="error-text" >Lot division is required</div>
                </div>
                <div v-if="form.procurement_divided_into_lots == 1" class="row">
                    <div class="col-md-6">
                        <br/><label>Quantity of lots</label>
                        <div :class="{ 'has-error-field': v.form.procurement_quantity_of_lots.$invalid }">
                            <el-input v-model="form.procurement_quantity_of_lots" type="number"></el-input>
                        </div>
                        <div v-if="v.form.procurement_quantity_of_lots.$invalid" class="error-text" >Quantity of lots is required</div>
                    </div>
                    <div v-if="fat_procurement_type == FATNoticeTypes.F02.value" class="row">
                        <div class="col-md-6">
                            <br/><label>Tenders may be submitted for</label>
                            <div :class="{ 'has-error-field': v.form.tenders_submitted_for.$invalid }">
                                <el-radio-group v-model="form.tenders_submitted_for">
                                    <el-radio :label="0" :value="0">All lots</el-radio>
                                    <el-radio :label="1" :value="1">One lot only</el-radio>
                                    <el-radio :label="2" :value="2">Maximum number of lots</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.tenders_submitted_for.$invalid" class="error-text" >Tenders submitted for is required.</div>
                        </div>
                        <div class="col-md-6" v-if="form.tenders_submitted_for == 2">
                            <br/><label>Maximum number of lots</label>
                            <div :class="{ 'has-error-field': v.form.procurement_max_number_lots.$invalid }">
                                <el-input v-model="form.procurement_max_number_lots"></el-input>
                            </div>
                            <div v-if="v.form.procurement_max_number_lots.$invalid" class="error-text" >Maximum number of lots is required/should be numeric</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Maximum number of lots that may be awarded to one tenderer (optional)</label>
                        <el-input v-model="form.procurement_max_awarded_lots"></el-input>
                        <div v-if="v.form.procurement_max_awarded_lots.$invalid" class="error-text" >Maximum number of lots should be numeric and less than or equal to the total number of lots</div>
                    </div>
                    <div class="col-md-12">
                        <br/><label>The contracting authority reserves the right to award contracts combining the following lots or groups of lots (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.procurement_right_to_award_contracts"></el-input>
                    </div>
                </div>
                <div v-if="form.procurement_divided_into_lots == 0" class="row">
                    <div class="col-md-6">
                        <br/><label>II.2. Description</label>
                        <br/><label>II.2.2. Additional CPV codes (optional)</label>
                        <el-select v-model="form.procurement_cpv_code_addl" filterable clearable>
                            <el-option v-for="(description, code) in cpv_code_choices[preliminary.contract_type]" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12" v-if="form.procurement_cpv_code_addl">
                    <br/><label>Supplementary Code</label>
                        <el-select v-model="form.procurement_supplementary_code_addl" filterable clearable multiple>
                            <span v-for="(parts, header) in supplementary_code_choices">
                                <el-option-group :label="header">
                                    <el-option-group v-for="(sub_parts, sub_header) in parts" :label="sub_header">
                                        <el-option v-for="(description, code) in sub_parts" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                                    </el-option-group>
                                </el-option-group>
                            </span>
                            <el-option  :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                    </div>
                    <div class="col-md-6">
                        <br/><label>II.2.3. Place of performance</label>
                        <br/><label>NUTS codes</label>
                        <div :class="{ 'has-error-field': v.form.procurement_nuts_code.$invalid }">
                            <el-select v-model="form.procurement_nuts_code" filterable clearable>
                                <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                    <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                        <span style="float: left">{{ c_values.name }}</span>
                                        <span class="secondary-selection">
                                            {{ c_abbrev }}
                                        </span>
                                    </el-option>
                                        <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                            <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                                <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                    <span style="float: left">{{ c1_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c1_abbrev }}
                                                    </span>
                                                </el-option>
                                                <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                    <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                        <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                            <span style="float: left">{{ c2_values.name }}</span>
                                                            <span class="secondary-selection">
                                                                {{ c2_abbrev }}
                                                            </span>
                                                        </el-option>
                                                            <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                                <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                    <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c3_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                    <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                        <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                            <span style="float: left">{{ c4_values.name }}</span>
                                                                            <span class="secondary-selection">
                                                                                {{ c3_abbrev }}
                                                                            </span>
                                                                        </el-option>
                                                                    </el-option-group>
                                                                </span>
                                                            </el-option-group>
                                                    </span>
                                                </el-option-group>
                                            </span>
                                    </el-option-group>
                                </el-option-group >
                            </el-select>
                        </div>
                        <div v-if="v.form.procurement_nuts_code.$invalid" class="error-text" >NUTS Code is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Main site or place of performance (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.procurement_main_site_of_performance"></el-input>
                    </div>
                    <div class="col-md-6">
                        <br/><label>II.2.4 Description of the procurement</label>
                        <div :class="{ 'has-error-field': v.form.procurement_description.$invalid }">
                            <el-input type="textarea" :rows="3" v-model="form.procurement_description"></el-input>
                        </div>
                        <div v-if="v.form.procurement_description.$invalid" class="error-text" >Description is required</div>
                    </div>
                    
                    <div v-if="fat_procurement_type == FATNoticeTypes.F02.value">
                        <div class="col-md-8">
                            <br/><label>II.2.5. Award criteria</label>
                            <div :class="{ 'has-error-field': v.form.procurement_award_criteria.$invalid }">
                                <el-radio-group v-model="form.procurement_award_criteria">
                                    <el-radio :label="0" :value="0">Criteria below</el-radio>
                                    <el-radio :label="1" :value="1">Price is not the only award criterion and all criteria are stated only in the procurement documents</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.procurement_award_criteria.$invalid" class="error-text" >Award Criteria is required</div>
                        </div>
                        <div class="col-md-12">
                            <el-checkbox v-model="form.procurement_quality_criteria">Quality criteria (optional)</el-checkbox>
                        </div>
                        <label>Enter criteria and weighting</label>
                        <div v-if="form.procurement_quality_criteria">
                            <div class="col-md-12">
                                <table>
                                   <thead>
                                    <tr>
                                        <th>Quality Criterion</th>
                                        <th>Weighting</th>
                                    </tr>
                                   </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in form.procurement_quality_criterion" :key="index">
                                        <td><el-input v-model="form.procurement_quality_criterion[index].criteria"></el-input></td>
                                        <td><el-input v-model="form.procurement_quality_criterion[index].weighting"></el-input></td>
                                        <td><el-button type="warning" @click="form.procurement_quality_criterion.splice(index)">Remove</el-button></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <br/><el-button type="primary" @click="form.procurement_quality_criterion.push({criteria: null, weighting: null})">Add</el-button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <br/><label>Cost criteria/price</label><br/>
                            <el-radio-group v-model="form.procurement_cost_criteria">
                                <el-radio :label="0" :value="0">Cost criteria</el-radio>
                                <el-radio :label="1" :value="1">Price</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="col-md-12" v-if="form.procurement_cost_criteria == 0">
                            <table>
                                <thead>
                                    <tr>
                                    <th>Cost Criterion</th>
                                    <th>Weighting</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index) in form.procurement_cost_criterion" :key="index">
                                    <td><el-input v-model="form.procurement_cost_criterion[index].criteria"></el-input></td>
                                    <td><el-input v-model="form.procurement_cost_criterion[index].weighting"></el-input></td>
                                    <td><el-button type="warning" @click="form.procurement_cost_criterion.splice(index)">Remove</el-button></td>
                                </tr>
                                </tbody>
                            </table>
                            <br/><el-button type="primary" @click="form.procurement_cost_criterion.push({criteria: null, weighting: null})">Add</el-button>
                        </div>

                        <div class="col-md-6">
                            <br/><label>II.2.6. Estimated value (excluding VAT) (optional)</label>
                            <br/><label style="color: #A9A9A9">For example, 200000 or 417709.28. For framework agreements or dynamic purchasing systems - estimated total maximum value for the entire duration of this lot</label>
                            <el-input v-model="form.procurement_estimated_value"></el-input>
                            <div v-if="v.form.procurement_estimated_value.$invalid" class="error-text" >Invalid format</div>
                        </div>
                        <div class="col-md-6">
                        <br/><label>Currency (optional)</label>
                            <el-select v-model="form.procurement_criteria_currency" filterable clearable>
                                <el-option v-for="(full_name, abbrev) in currency_choices" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                            </el-select>
                        </div>

                        <div class="col-md-6">
                            <br/><label>II.2.7. Duration of the contract, framework agreement or dynamic purchasing system</label>
                            <div :class="{ 'has-error-field': v.form.procurement_contract_duration.$invalid }">
                                <el-radio-group v-model="form.procurement_contract_duration">
                                    <el-radio :label="0" :value="0">Duration</el-radio>
                                    <el-radio :label="1" :value="1">Date range</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.procurement_contract_duration.$invalid" class="error-text" >Contract duration is required</div>
                        </div>
                        <div v-if="form.procurement_contract_duration == 0">
                            <div class="col-md-6">
                                <br/><label>Duration type</label>
                                <div :class="{ 'has-error-field': v.form.procurement_duration_type.$invalid }">
                                    <el-radio-group v-model="form.procurement_duration_type">
                                        <el-radio :label="0" :value="0">Duration in months</el-radio>
                                        <el-radio :label="1" :value="1">Duration in days</el-radio>
                                    </el-radio-group>
                                </div> 
                                <div v-if="v.form.procurement_duration_type.$invalid" class="error-text" >Duration type is required</div>
                            </div>
                            <div class="col-md-6">
                                <br/><label>Duration</label>
                                <br/><label style="color: #A9A9A9">The duration must be a number, for example 12</label>
                                <div :class="{ 'has-error-field': v.form.procurement_duration.$invalid }">
                                    <el-input v-model="form.procurement_duration"></el-input>
                                </div>
                                <div v-if="v.form.procurement_duration.$invalid" class="error-text" >Duration is required</div>
                            </div>
                        </div>

                        <div v-if="form.procurement_contract_duration == 1">
                            <br/><label>Date Range</label>
                            <br/><label style="color: #A9A9A9">Enter the start date, the end date or both</label>
                            <div class="col-md-6">
                                <label>Start Date</label>
                                <br/><el-date-picker v-model="form.procurement_contract_start_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                            </div>
                            <div class="col-md-6">
                                <label>End Date</label>
                                <br/><el-date-picker v-model="form.procurement_contract_end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <br/><label>This contract is subject to renewal</label>
                            <div :class="{ 'has-error-field': v.form.procurement_subject_to_renewal.$invalid }">
                                <el-radio-group v-model="form.procurement_subject_to_renewal">
                                    <el-radio :label="1" :value="1">Yes</el-radio>
                                    <el-radio :label="0" :value="0">No</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.procurement_subject_to_renewal.$invalid" class="error-text" >Subject to renewal is required</div>
                        </div>

                        <div class="col-md-6" v-if="form.procurement_subject_to_renewal">
                            <br/><label>Description of renewals</label>
                            <div :class="{ 'has-error-field': v.form.procurement_renewal_description.$invalid }">
                                <el-input type="textarea" v-model="form.procurement_renewal_description" :rows="5"></el-input>
                            </div> 
                            <div v-if="v.form.procurement_renewal_description.$invalid" class="error-text" >Renewal description is required</div>
                        </div>
                        
                        <div class="col-md-6">
                            <br/><label>II.2.10. Information about variants</label>
                            <br/><label>Variants will be accepted</label>
                            <br/><label style="color: #A9A9A9">Can the contracting authority accept bids which propose innovative ways of delivering a service that differs from that specified, alternative pricing structures, or risk profiles?</label>
                            <div :class="{ 'has-error-field': v.form.procurement_accept_variants.$invalid }">
                                <el-radio-group v-model="form.procurement_accept_variants">
                                    <el-radio :label="1" :value="1">Yes</el-radio>
                                    <el-radio :label="0" :value="0">No</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.procurement_accept_variants.$invalid" class="error-text" >Accept variants is required</div>
                        </div>

                        <div class="col-md-6">
                            <label>II.2.11. Information about options</label>
                            <br/><label>Options will be accepted</label>
                            <br/><label style="color: #A9A9A9">Can the term of the agreements be extended at the discretion of the contracting authority?</label>
                            <div :class="{ 'has-error-field': v.form.procurement_accept_options.$invalid }">
                                <el-radio-group v-model="form.procurement_accept_options">
                                    <el-radio :label="1" :value="1">Yes</el-radio>
                                    <el-radio :label="0" :value="0">No</el-radio>
                                </el-radio-group>
                            </div> 
                            <div v-if="v.form.procurement_accept_options.$invalid" class="error-text" >Accept options is required</div>
                        </div>
                        
                        <div class="col-md-12" v-if="form.procurement_accept_options">
                            <label>Description of options</label>
                            <br/>
                            <div :class="{ 'has-error-field': v.form.procurement_option_description.$invalid }">
                                <el-input v-model="form.procurement_option_description" type="textarea" :rows="5"></el-input>
                            </div>
                            <div v-if="v.form.procurement_option_description.$invalid" class="error-text" >Accept options is required</div>
                        </div>

                        <div class="col-md-12">
                            <br/><label>II.2.12. Information about electronic catalogues</label>
                            <br/><el-checkbox v-model="form.procurement_ecatalogue">Tenders must be presented in the form of an electronic catalogues or include an electronic catalogue (optional)</el-checkbox>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <br/><label>II.2.14. Additional information (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.procurement_addl_description"></el-input>
                    </div>
                </div>

                <div v-if="fat_procurement_type == FATNoticeTypes.F01.value">
                    <div class="col-md-12">
                        <br/><label>II.3. Estimated date of publication of contract notice</label>
                        <br/>
                        <div :class="{ 'has-error-field': v.form.procurement_estimated_date_contract_notice.$invalid }">
                            <el-date-picker v-model="form.procurement_estimated_date_contract_notice" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                        </div>
                        <div v-if="v.form.procurement_estimated_date_contract_notice.$invalid" class="error-text" >Estimated date of publication is required</div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
    
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
        numeric,
        required, requiredIf
     } from '@vuelidate/validators'
     import * as moment from 'moment'
import { FATNoticeTypes } from '../../../../constants';

 
     const SECTION_TWO = 2;
     
     export default {
         props: ['previous_form', 'user', 'procurement', 'saved_form', 'preliminary', 'cpv_code_choices', 'currency_choices', 'supplementary_code_choices', 'nuts_code_choices', 'procurement_stage', 'is_not_submitted', 'fat_procurement_type'],
         data() {
             return {
                optionsCpv: [],
                listCpv: [],
                loading: false,
                form: {
                    procurement_title: null,
                    procurement_reference_number: null,
                    procurement_cpv_code: null,
                    procurement_short_description: null,
                    procurement_estimated_total_value: null,
                    procurement_currency: null,
                    procurement_divided_into_lots: null,
                    procurement_supplementary_code: [],
                    procurement_supplementary_code_addl: [],
                    procurement_estimated_date_contract_notice: null,
                    procurement_quantity_of_lots: null,
                    procurement_max_awarded_lots: null,
                    procurement_right_to_award_contracts: null,
                    procurement_cpv_code_addl: null,
                    procurement_nuts_code: null,
                    procurement_description: null,
                    procurement_addl_description: null,
                    procurement_main_site_of_performance: null,
                    //f02
                    procurement_max_number_lots: null,
                    tenders_submitted_for: null,
                    procurement_award_criteria: null,
                    procurement_quality_criteria: null,
                    procurement_quality_criterion: [{criteria: null, weighting: null}],
                    procurement_cost_criteria: null,
                    procurement_cost_criterion: [{criteria: null, weighting: null}],
                    procurement_estimated_value: null,
                    procurement_criteria_currency: null,
                    procurement_contract_duration: null,
                    procurement_duration_type: null,
                    procurement_duration: null,
                    procurement_subject_to_renewal: null,
                    procurement_renewal_description: null,
                    procurement_contract_start_date: null,
                    procurement_contract_end_date: null,
                    procurement_accept_variants: null,
                    procurement_accept_options: null,
                    procurement_option_description: null,
                    procurement_price_weighting: null,
                    procurement_ecatalogue: null
                }
            }
         },
        setup: () => ({
             v: useVuelidate()
          }),
        validations() {
            const procMaxAwardedValidate = () => {
                if(this.form.procurement_max_awarded_lots == null || this.form.procurement_max_awarded_lots == '') return true;
                return this.form.procurement_max_awarded_lots && this.form.procurement_max_awarded_lots <= this.form.procurement_quantity_of_lots
            }


            let rules =  {
             form: {
                procurement_title: {
                required,
                $autoDirty: true
                },
                procurement_cpv_code: {
                required,
                $autoDirty: true
                },
                procurement_short_description: {
                required,
                $autoDirty: true
                },
                procurement_divided_into_lots: {
                required,
                $autoDirty: true
                },
                procurement_currency: {
                required,
                $autoDirty: true
                },
                procurement_quantity_of_lots: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 1}),
                    numeric,
                    $autoDirty: true
                },
                procurement_estimated_date_contract_notice: {
                    required: requiredIf(function () {return this.fat_procurement_type == FATNoticeTypes.F01.value}),
                    $autoDirty: true
                },
                procurement_nuts_code: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0}),
                    $autoDirty: true
                },
                procurement_description: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0}),
                    $autoDirty: true
                },
                procurement_max_number_lots: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 1 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.tenders_submitted_for == 2}),
                    $autoDirty: true
                },
                procurement_award_criteria: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                tenders_submitted_for: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 1 && this.fat_procurement_type == FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                procurement_quality_criterion: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.procurement_quality_criteria}),
                    $autoDirty: true
                },
                procurement_cost_criteria: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                procurement_cost_criterion: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.procurement_cost_criteria == 0}),
                    $autoDirty: true
                },
                procurement_contract_duration: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value}),
                    $autoDirty: true
                },
                procurement_duration_type: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.procurement_contract_duration == 0}),
                    $autoDirty: true
                },
                procurement_duration: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.procurement_contract_duration == 0}),
                    $autoDirty: true
                },
                procurement_subject_to_renewal: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value }),
                    $autoDirty: true
                },
                procurement_renewal_description: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.procurement_subject_to_renewal}),
                    $autoDirty: true
                },
                procurement_accept_variants: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value }),
                    $autoDirty: true
                },
                procurement_accept_options: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value }),
                    $autoDirty: true
                },
                procurement_option_description: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0 && this.fat_procurement_type == FATNoticeTypes.F02.value && this.form.procurement_accept_options}),
                    $autoDirty: true
                },
                // optional
                procurement_estimated_total_value: {
                    numeric,
                    $autoDirty: true
                },
                procurement_estimated_value: {
                    numeric,
                    $autoDirty: true
                },
                procurement_max_awarded_lots: {
                    numeric,
                    procMaxAwardedValidate,
                    $autoDirty: true
                }
            }
         }

        return rules
        },
        mounted()  {
            if(this.saved_form) {
                this.form = this.saved_form
                if(!this.form.procurement_quality_criterion) this.form.procurement_quality_criterion = [{criteria: null, weighting: null}]
                if(!this.form.procurement_cost_criterion) this.form.procurement_cost_criterion = [{criteria: null, weighting: null}]
                this.form.procurement_quality_criteria = this.convertBool(this.form.procurement_quality_criteria)
                this.searchCPV(this.form.procurement_cpv_code);
            }
            else {
                this.form.procurement_title = this.procurement.name
                this.form.procurement_short_description = this.procurement.description
            }

            var self = this;

            console.log('form', this.form.procurement_quality_criterion)

            Object.values(this.cpv_code_choices).map((values) => {
                Object.entries(values).forEach((desc) => {
                    self.listCpv.push({ label: desc[0] + ": " + desc[1], value: desc[0] })
                })
            })
        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts', 'ProcurementProjectStatuses']),
        },
        methods: {
            saveForm() {
                if(this.form.procurement_divided_into_lots != 1) this.form.procurement_quantity_of_lots = null

                if(this.form.procurement_estimated_date_contract_notice) {
                    let formattedDate = moment(
                        this.form.procurement_estimated_date_contract_notice
                    ).format('YYYY-MM-DD')

                    this.form.procurement_estimated_date_contract_notice = formattedDate
                }
                
                this.$emit('save', SECTION_TWO, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form;
            },
            contractTypeTranslation(type) {
                switch (type) {
                    case 0: return 'Works'
                    case 1: return 'Supplies'
                    case 2: return 'Services'
                }
            },
            convertBool(int) {
                switch(int) {
                    case 1:
                        case true: return true
                    default: return false
                }
            },
            searchCPV(query) {
                if (query) {
                this.loading = true
                    setTimeout(() => {
                    this.loading = false
                    this.optionsCpv = this.listCpv.filter((item) => {
                        return item.label.toLowerCase().includes(query.toLowerCase())
                    })
                    this.optionsCpv = this.optionsCpv.slice(0, 10);
                }, 500)
                } else {
                    this.optionsCpv = []
                }
            }
        }
    }
 </script>
 